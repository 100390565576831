import { makeStyles } from '@material-ui/core/styles';
import './styles.scss';

export const useStyles = makeStyles(theme => ({
	container: {
		width: 406,
		display: 'flex',
	},
	closeButtonContainer: {
		position: 'absolute',
		zIndex: 2,
	},
	buttonShadow: {
		position: 'absolute',
		overflow: 'hidden',
		marginLeft: -25.5,
		width: 25.5,
		height: 51,
		borderTopLeftRadius: 65,
		borderBottomLeftRadius: 65,
		borderRadius: '50% 0px 0px 50%',
		boxShadow: theme.shadows[4],
		backgroundColor: 'transparent',
	},
	spacer: theme.mixins.toolbar,
}));

export const useDrawerClasses = makeStyles(() => ({
	paper: {
		overflowY: 'initial',
	},
}));

export const useIndicatorStyles = makeStyles(theme => ({
	root: {
		flexDirection: 'column',
		padding: '62px 30px 23px 36px',
	},
	title: {
		fontWeight: 700,
		fontSize: 18,
	},
	error: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.secondary.main,
	},
	progress: {
		flexGrow: 1,
		marginTop: theme.spacing(2),
	},
}));

export const useCloseButtonStyles = makeStyles(theme => ({
	root: {
		width: 51,
		height: 51,
		backgroundColor: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.common.white,
		},
	},
	edgeStart: {
		marginLeft: -25.5,
	},
}));

export const useMessageInputStyles = makeStyles(() => ({
	sendButton: {
		fontWeight: 700,
	},
	avatar: {
		width: 30,
		height: 30,
		borderRadius: '50%',
	},
}));

export const useAddFileStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		display: 'inline-block',

		'& .rfu-file-upload-button': {
			position: 'unset',
			'& .rfu-file-input': {
				display: 'none',
			},
		},
	},

	uploadImage: {
		width: 30,
		height: 30,
	},

	uploadIcon: {
		'& svg': {
			cursor: 'pointer',
		},
	},
}));

export const useUploadPreviewStyles = makeStyles(() => ({
	root: {
		position: 'relative',

		'& .rfu-image-previewer': {
			display: 'flex',
			alignItems: 'end',
			marginBottom: '5px',

			'& .rfu-image-previewer__image': {
				marginRight: '10px',
				overflow: 'hidden',
				height: 'unset !important',
				position: ' relative',

				'& .rfu-thumbnail__wrapper': {
					height: 'unset !important',

					'& img': {
						borderRadius: '5px',
					},

					'& .rfu-icon-button': {
						'& svg': {
							position: 'absolute',
							cursor: 'pointer',
						},
					},
				},

				' & .rfu-loading-indicator__spinner': {
					position: 'absolute',
					border: 'unset !important',
					bottom: 0,
					left: 'calc(50% - 10px)',
				},
			},

			'& .rfu-image-upload-button': {
				display: 'none',
			},
		},

		'& .rfu-file-previewer': {
			'& ol': {
				paddingLeft: '20px',
				margin: '5px 0',

				'& .rfu-file-previewer__file': {
					'& svg': {
						marginRight: '5px',
					},

					'& .rfu-file-previewer__close-button': {
						cursor: 'pointer',
						marginLeft: '5px',
					},
				},
			},
		},
	},
}));

export const useEditMessageFormStyles = makeStyles(() => ({
	root: {
		'& textarea.rta__textarea': {
			border: 'none',
			boxShadow: 'none',
		},
	},

	button: {
		cursor: 'pointer',
	},
}));
