import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthenticationProvider } from 'authentication';
import { ANALYTICS_EVENTS } from './constants/analytics-events';
import { ChatProvider } from './components/Chat';
import { theme, ThemeProvider } from './theme';
import { configureStreamChat } from './services';
import analytics from './services/analytics';
import { RootStoreProvider } from './stores';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const history = createBrowserHistory();

// Track every page load from router listener
history.listen(({ pathname }) => {
	analytics.track(ANALYTICS_EVENTS.PAGE_LOAD, { pathname }, true);
});

const chat = configureStreamChat();
const queryClient = new QueryClient();

ReactDOM.render(
	<RootStoreProvider>
		<ThemeProvider theme={theme}>
			<QueryClientProvider client={queryClient}>
				<ChatProvider streamChat={chat}>
					<Router history={history}>
						<AuthenticationProvider>
							<App />
						</AuthenticationProvider>
					</Router>
				</ChatProvider>
				<ReactQueryDevtools initialIsOpen={false} position='bottom-left' />
			</QueryClientProvider>
			<CssBaseline />
		</ThemeProvider>
	</RootStoreProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
