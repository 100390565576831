import { useEffect, useState } from 'react';
import { Card } from '../typings/cms';
import { textToAnchor } from '../components/PageCard';
import { InsightsAnchor } from '../components/Insights';
import { headerHeight } from '../theme/metrics';

// TODO: This will fail in the case you have a few very short cards in the
//       bottom of the page. To handle that edge case, compare the url
//       fragment with the current fragment.

const SCROLL_TARGET_SELECTOR = 'main';

const activeItemHandler = (cards: Card[] | undefined, setActive: any) => {
	if (cards == undefined) {
		return () => {
		};
	}

	const scrollTarget = document.querySelector(SCROLL_TARGET_SELECTOR);
	if (scrollTarget == null) {
		return () => {
		};
	}

	const fragments: string[] = [textToAnchor(InsightsAnchor)]
		.concat(cards.map((c: Card): string => textToAnchor(c.toc)))
		.filter(v => v != '');

	const markCurrentFragmentAsActive = () => {
		// Reset the active to empty
		setActive('');

		for (let i = 0; i < fragments.length; i++) {
			const target = document.getElementById(fragments[i])?.parentElement;
			if (typeof target === 'undefined' || target === null) {
				return;
			}

			const rect = target.getBoundingClientRect();
			const windowBottom = (window.innerHeight || document.documentElement.clientHeight);
			const bottomLeeway = Math.max(headerHeight, rect.height / 3);
			const topLeeway = Math.min(windowBottom / 3, rect.height / 3);
			if (rect.bottom > bottomLeeway && windowBottom > rect.top + topLeeway) {
				setActive(fragments[i]);
				return;
			}
		}
	};

	markCurrentFragmentAsActive();
	scrollTarget.addEventListener('scroll', markCurrentFragmentAsActive);

	return () => {
		scrollTarget.removeEventListener('scroll', markCurrentFragmentAsActive);
	};
};

interface useActiveFragmentReturnType {
	activeFragment: string
}

export function useActiveFragment(cards: Card[] | undefined): useActiveFragmentReturnType {
	const [active, setActive] = useState<string>('');

	useEffect(() => {
		activeItemHandler(cards, setActive);
	}, [cards]);

	return { activeFragment: active };
}
