import { useRef, useState, useEffect, MutableRefObject } from 'react';
import { usePrevious } from './usePrevious';

export interface Options {
	timeout: number;
	onReached?: () => void;
}

export function useOnScreen(
	options: Options = {
		onReached: () => {},
		timeout: 2000,
	}
): [MutableRefObject<HTMLElement>] {
	const ref = useRef(null!);
	const { onReached, timeout } = options;
	const [isIntersecting, setIntersecting] = useState(false);
	const prevIsIntersecting = usePrevious(isIntersecting);

	const timer = useRef<NodeJS.Timeout>();

	useEffect(() => {
		if (!prevIsIntersecting && isIntersecting) {
			timer.current = setTimeout(() => {
				if (onReached) onReached();
			}, timeout);
		} else if (prevIsIntersecting && !isIntersecting && timer.current) {
			clearTimeout(timer.current);
			timer.current = undefined;
		}

		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		};
	}, [prevIsIntersecting, isIntersecting]);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => setIntersecting(entry.isIntersecting),
			{
				root: null,
				rootMargin: '64px 0px 0px 0px',
				threshold: [0.75],
			}
		);

		const { current } = ref;

		if (current) {
			observer.observe(current);
		}

		// Remove the observer as soon as the component is unmounted
		return () => {
			current && observer.unobserve(current);
		};
	}, [ref.current]);

	return [ref];
}
