import { makeStyles } from '@material-ui/core/styles';
import { defaultPadding } from '../../theme/padding';

export const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		width: `calc(100% - ${defaultPadding.right}px)`,
	},

	barCard: {
		position: 'relative',
	},

	commentInfo: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 1199,
		cursor: 'pointer',
		width: 20,

		'& img': {
			width: 20,
			minWidth: 20,
		},

		'& p': {
			position: 'absolute',
			top: '0px',
			width: '20px',
			fontWeight: 'bold',
			fontSize: '10px',
			lineHeight: '19px',
			zIndex: 1199,
			textAlign: 'center',
			color: theme.palette.common.white,
		},
	},

	error: {
		color: theme.palette.secondary.main,
		padding: theme.spacing(3, 6),
	},
}));
