/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
import { defaultParseOptions, parseCsv } from 'utils';
import { Landscape, Insight, Card, Client, Mentionable } from 'typings/cms';

export const mapCard = (row: any): Card=> {
	return {
		id: row._id,
		created: new Date(row._created * 1000),
		modified: new Date(row._modified * 1000),
		by: row._by,
		mby: row._mby,
		data: parseCsv(String(row.data), {
			...defaultParseOptions,
			type: row.type,
		}),
		description: row.description,
		metadata: row.metadata,
		published: Boolean(row.published),
		subtitle: row.subtitle,
		title: row.title,
		toc: row.toc,
		type: row.type,
		isTitle: row.is_title !== undefined ? row.is_title : false,
	};
};

const mapClient = (row: any): Client => {
	return {
		id: row._id,
		created: new Date(row._created * 1000),
		modified: new Date(row._modified * 1000),
		by: row._by,
		mby: row._mby,
		analyst: row.analyst,
		name: row.name,
		display: row.display, // Only when populate == false
	};
};

const mapInsight = (row: any): Insight => {
	return {
		id: row._id,
		created: new Date(row._created * 1000),
		modified: new Date(row._modified * 1000),
		by: row._by,
		mby: row._mby,
		text: row.text,
	};
};

export const mapLandscape = (row: any): Landscape => {
	return {
		id: row._id,
		created: new Date(row._created * 1000),
		modified: new Date(row._modified * 1000),
		cards: (row?.cards || []).filter((c: any) => !!c._id && c.published).map(mapCard),
		client: row.client && mapClient(row.client),
		description: row.description,
		insights: (row?.insights || []).map(mapInsight),
		title: row.title,
	};
};


export const mapMentionable = (row: any): Mentionable => {
	return {
		id: row.id,
		name: row.profile.name,
		tenantId: row.profile.tenant_id,
	};
};
