import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useStyles } from '../../PageCard/styles';
import { DataParser, DataRecord } from '../../../typings/app';

export interface HtmlBlobProps {
	data: DataRecord[],
}

const HtmlBlob: React.FC<HtmlBlobProps> & DataParser = ({ data }) => {
	const classes = useStyles();

	const content = data.reduce((prev, current) => {
		return { html: prev.html + current.html };
	});

	return (
		<Paper elevation={0} classes={{ root: classes.root }}>
			<div dangerouslySetInnerHTML={{ __html: content.html }} />
		</Paper>
	);
};

HtmlBlob.dataParser = (records: DataRecord[]) => {
	const componentData: DataRecord = records;
	const componentProps = {};
	return { componentData, componentProps };
};

export default HtmlBlob;
