import React, { useEffect } from 'react';
import {
	KEY_CODES,
	MessageInputProps,
	UploadsPreview,
	useChannelContext,
	useMessageInput,
	useTranslationContext,
} from 'stream-chat-react';
import { FileUploadButton } from 'react-file-utils';
import FileUploadIconImage from 'assets/images/attach-file-icon.png';
import { ChatAutoComplete } from './ChatAutoComplete';
import { useAddFileStyles, useEditMessageFormStyles, useUploadPreviewStyles } from './styles';

export const EditMessageForm: React.FC<MessageInputProps> = (
	props
) => {
	const {
		additionalTextareaProps = {},
		clearEditingState,
		disabled = false,
		focus = false,
		grow = true,
		maxRows = 10,
		publishTypingEvent = true,
	} = props;

	const { t } = useTranslationContext();

	const messageInput = useMessageInput({
		...props,
		additionalTextareaProps,
		disabled,
		focus,
		grow,
		maxRows,
		publishTypingEvent,
	});


	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.keyCode === KEY_CODES.ESC && clearEditingState) clearEditingState();
		};

		document.addEventListener('keydown', onKeyDown);
		return () => document.removeEventListener('keydown', onKeyDown);
	}, [clearEditingState]);

	const channelContext = useChannelContext();
	const uploadClasses = useAddFileStyles();
	const uploadPreviewClasses = useUploadPreviewStyles();
	const editMessageFormClasses = useEditMessageFormStyles();

	return (
		<div className='str-chat__edit-message-form'>
			<div className={uploadPreviewClasses.root}>
				{messageInput.isUploadEnabled && <UploadsPreview {...messageInput} />}
			</div>
			<form onSubmit={messageInput.handleSubmit} className={editMessageFormClasses.root}>
				<div className='str-chat__input-flat-container'>
					{messageInput.isUploadEnabled && (
						<div className={uploadClasses.root} data-testid='fileinput'>
							<FileUploadButton
								accepts={channelContext.acceptedFiles}
								disabled={messageInput.maxFilesLeft === 0}
								handleFiles={messageInput.uploadNewFiles}
								multiple={channelContext.multipleUploads}
							>
								<img className={uploadClasses.uploadImage} src={FileUploadIconImage} />
							</FileUploadButton>
						</div>
					)}
					<ChatAutoComplete
						additionalTextareaProps={additionalTextareaProps}
						commands={messageInput.getCommands()}
						grow={grow}
						handleSubmit={messageInput.handleSubmit}
						innerRef={messageInput.textareaRef}
						maxRows={maxRows}
						onChange={messageInput.handleChange}
						onPaste={messageInput.onPaste}
						onSelectItem={messageInput.onSelectItem}
						placeholder={t('Type your message')}
						rows={1}
						value={messageInput.text}
					/>
				</div>
				<div className='str-chat__message-team-form-footer'>
					<div className='str-chat__edit-message-form-options'>
					</div>
					<div>
						<button
							onClick={() => {
								if (clearEditingState) {
									clearEditingState();
								}
							}}
							className={editMessageFormClasses.button}
						>
							{t('Cancel')}
						</button>
						<button type='submit' className={editMessageFormClasses.button}>{t('Send')}</button>
					</div>
				</div>
			</form>
		</div>
	);
};
