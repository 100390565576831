import { TickLabelProps } from '@visx/axis/lib/types';
import { TickFormatter } from '@visx/axis';
import { TextProps } from '@visx/text/lib/Text';

export const AXIS_LABEL_SIZE = 16;

/**
 * Presentation properties for the axes of the graph
 */
export interface AxisProps {
	leftAxisLabel: string,
	leftAxisTickLabelProps: TickLabelProps<unknown>;
	leftAxisTickFormatter?: TickFormatter<any>;
	leftAxisTickStroke: string;
	leftAxisWidthPx: number;
	bottomAxisHeightPx: number;
	bottomAxisLabel: string,
	bottomAxisNumTicks: number;
	bottomAxisTickFormatter?: TickFormatter<any>;
	bottomAxisTickLabelProps: TickLabelProps<unknown>;
	bottomAxisTickStroke: string;
}

export const defaultLeftAxisProps: Partial<TextProps> = {
	dx: '-0.5em',
	dy: '0.25em',
	fill: 'black',
	fontFamily: 'Ubuntu',
	fontSize: 12,
	fontWeight: 400,
	textAnchor: 'end',
};

export const defaultBottomAxisProps: Partial<TextProps> = {
	angle: -45,
	dx: '0.25em',
	fill: 'black',
	fontFamily: 'Ubuntu',
	fontSize: 12,
	fontWeight: 400,
	textAnchor: 'end',
};

export const defaultAxisProps: AxisProps = {
	leftAxisLabel: 'Y Axis',
	leftAxisTickLabelProps: () => defaultLeftAxisProps,
	leftAxisTickStroke: 'black',
	leftAxisWidthPx: 36,
	bottomAxisHeightPx: 40,
	bottomAxisLabel: 'X Axis',
	bottomAxisNumTicks: 20,
	bottomAxisTickLabelProps: () => defaultBottomAxisProps,
	bottomAxisTickStroke: 'black',
};
