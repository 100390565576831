import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import analytics from 'services/analytics';
import { ANALYTICS_EVENTS } from 'constants/analytics-events';
import { useStyles } from './styles';

const analystScheduleUrl = process.env.REACT_APP_ANALYST_SCHEDULE_URL;

interface Props {
	landscapeId?: string;
	analyst?: string;
}

export const AnalystCard: React.FC<Props> = ({ analyst, landscapeId }) => {
	const classes = useStyles();
	const [avatar, setAvatar] = useState<string>();

	const handleClickOpen = () => {
		if (landscapeId !== undefined) {
			analytics.track(ANALYTICS_EVENTS.SCHEDULE_CLICK, {
				landscape_id: landscapeId,
			});
		}

		if (analystScheduleUrl) {
			window.open(analystScheduleUrl, '_blank')?.focus();
		}
	};

	if (typeof analyst !== 'undefined') {
		import(`../../assets/images/${analyst.replaceAll(' ', '')}.png`)
			.then(r => setAvatar(r.default))
			.catch(() => setAvatar(undefined)); // On error use the default avatar
	}

	return (
		<>
			<Paper elevation={0} classes={{ root: classes.root }}>
				{analyst && <Avatar alt={analyst} src={avatar} className={classes.avatar} />}
				<Typography className={classes.title}>
					Questions about this landscape?
				</Typography>
				<Typography className={classes.subtitle}>
					Schedule a walkthrough with a Speciate analyst.
				</Typography>
				{analystScheduleUrl ? (
					<Button fullWidth variant='contained' className={classes.button} color='secondary' onClick={handleClickOpen}>
						Schedule
					</Button>
				) : null}
			</Paper>
		</>
	);
};
