import React, { useCallback } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SecureRoute as OktaSecureRoute } from '@okta/okta-react';
import { RouteProps } from 'react-router-dom';
import { useAuthentication } from '../authentication';

const useStyles = makeStyles(theme => ({
	content: {
		flexGrow: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		overflow: 'auto',
		position: 'relative',
		flexDirection: 'column',
		backgroundColor: theme.palette.text.primary,
	},
	message: {
		marginBottom: theme.spacing(4),
	},
}));

export const SecureRoute: React.FunctionComponent<RouteProps> = ({ children, ...props }) => {
	const classes = useStyles();

	const { isAuthenticated, hasError, logOut } = useAuthentication();

	const handleLogout = useCallback(() => logOut(), []);

	return (
		<OktaSecureRoute {...props}>
			{
				!isAuthenticated && !hasError ? (
					<main className={classes.content}>
						<Typography className={classes.message} color='secondary'>Loading</Typography>
						<CircularProgress size={24} color='secondary' />
					</main>
				) : (
					hasError ? (
						<main className={classes.content}>
							<Typography className={classes.message} color='secondary'>Oops! Something went wrong.</Typography>
							<Button onClick={handleLogout} variant='outlined' color='secondary'>Try again</Button>
						</main>
					) : children
				)
			}
		</OktaSecureRoute>
	);
};
