import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Card } from 'typings/cms';
import { useStyles } from './styles';
import { InsightsAnchor } from '../Insights';
import { textToAnchor } from '../PageCard';
import { useActiveFragment } from '../../hooks/useActiveFragment';

export interface JumpToMenuProps {
	cards?: Card[];
	isMenu?: boolean;
	isLoading?: boolean;
	isError?: boolean;
}

const JumpToMenu: React.FC<JumpToMenuProps> = ({ cards, ...props }) => {
	const classes = useStyles(props);

	const shortcuts = cards?.filter(c => typeof c.toc === 'string' && !!c.toc).map((card) => {
		return { text: card.toc, anchor: textToAnchor(card.toc, true) };
	});

	const { activeFragment } = useActiveFragment(cards);

	return (
		<Paper elevation={0} classes={{ root: classes.root }}>
			<Typography className={classes.title}>Jump To:</Typography>
			<Typography variant='caption'>
				{props.isLoading && <p className={classes.loading}>Loading...</p>}
				<ul className={classes.list}>
					{!props.isLoading &&
					<li className={activeFragment == textToAnchor(InsightsAnchor) ? 'active' : undefined}>
						<a className={'toc_link '} href={textToAnchor(InsightsAnchor, true)} title={'Key Insights'}>Key
							Insights</a>
					</li>
					}
					{shortcuts?.map((s, idx) =>
						<li key={idx} className={activeFragment == s.anchor.substr(1) ? 'active' : undefined}>
							<a className={'toc_link'} href={s.anchor} title={s.text}>{s.text}</a>
						</li>
					)}
				</ul>
			</Typography>
		</Paper>
	);
};

export default JumpToMenu;
