import { reaction } from 'mobx';
import { serialize, deserialize } from 'serializr';
import { save, load } from 'utils/storage';
import { RootStore } from './rootStore';

const ROOT_STATE_STORAGE_KEY = '@@rootStoreData';

export async function configureStore(): Promise<RootStore> {
	let rootStore = new RootStore();

	try {
		const data = await load(ROOT_STATE_STORAGE_KEY);

		const rootStoreFromCache = deserialize(RootStore, data);

		if (rootStoreFromCache) {
			rootStore = rootStoreFromCache;
		}
	} catch (e) {
		console.log(e);
	}

	reaction(
		() => serialize(rootStore),
		json => {
			save(ROOT_STATE_STORAGE_KEY, json);
		}
	);

	return rootStore;
}
