import React, { ReactElement, SyntheticEvent } from 'react';
import Comment from 'assets/images/Comment.svg';
import EmptyComment from 'assets/images/EmptyComment.svg';
import { useStyles } from './styles';

interface Props {
	messageCount: number;
	onClick: (e: SyntheticEvent) => void;
	className?: string;
}

export const MessagesBadge = (props: Props): ReactElement => {
	const {	messageCount, onClick, className = null } = props;
	const classes = useStyles();
	const commentImage = messageCount > 0 ? Comment : EmptyComment;

	return (
		<div
			className={className ? className : classes.commentInfo}
			onClick={onClick}
		>
			<img src={commentImage} />
			<p>{messageCount}</p>
		</div>
	);
};

