import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header';
import { headerHeight } from '../../theme/metrics';

const useStyles = makeStyles(theme => ({
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: `calc(100vh - ${headerHeight}px)`,
		overflow: 'auto',
		position: 'relative',
		marginTop: `${headerHeight}px`,
	},
	sidebar: {
		marginTop: `${headerHeight}px`,
		display: 'flex',
		zIndex: 1001,
	},
}));

export interface MainLayoutProps {
	sidebar?: ReactElement;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ sidebar, children }) => {
	const classes = useStyles();

	return (
		<>
			<Header />
			<div className={classes.sidebar}>{sidebar}</div>
			<main className={classes.content}>{children}</main>
		</>
	);
};
