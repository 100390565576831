const promisify = (fn: any): Promise<any> => {
	return new Promise((resolve, reject) => {
		try {
			const value = fn.call(null);
			resolve(value);
		} catch (e) {
			reject(e);
		}
	});
};

/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export async function loadString(key: string): Promise<string | null> {
	return promisify(() => window.localStorage.getItem(key));
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export async function saveString(key: string, value: string): Promise<boolean> {
	try {
		await promisify(() => window.localStorage.setItem(key, value));
		return true;
	} catch {
		return false;
	}
}

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export async function load(key: string): Promise<any | null> {
	try {
		const value = await promisify(() => window.localStorage.getItem(key));
		return JSON.parse(value);
	} catch {
		return null;
	}
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export async function save(key: string, value: unknown): Promise<boolean> {
	try {
		await promisify(() =>
			window.localStorage.setItem(key, JSON.stringify(value))
		);
		return true;
	} catch {
		return false;
	}
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export async function remove(key: string): Promise<void> {
	try {
		await promisify(() => window.localStorage.removeItem(key));
	} catch {
		// Nothing here
	}
}

/**
 * Burn it all to the ground.
 */
export async function clear(): Promise<void> {
	try {
		await promisify(() => window.localStorage.clear());
	} catch {
		// Nothing here
	}
}
