import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { configureStore } from './configure';
import { RootStore } from './rootStore';

const rootStoreContext = createContext<RootStore>(null!);

export const RootStoreProvider: React.FC = (props) => {
	const [ready, setReady] = useState(false);
	const storeRef = useRef<RootStore>();

	useEffect(() => {
		(async () => {
			storeRef.current = await configureStore();
			setReady(true);
		})();
	}, []);

	if (!ready) return null;

	// @ts-ignore
	return <rootStoreContext.Provider {...props} value={storeRef.current} />;
};

export const useStore: () => RootStore = () => useContext(rootStoreContext);
