import { makeStyles } from '@material-ui/core/styles';
import { COLOR_LIGHT_GREY } from '../../../theme';
import { headerBorderWidth } from '../../../theme/metrics';

export const useStyles = makeStyles(() => ({
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		borderRightStyle: 'solid',
		borderRightWidth: headerBorderWidth,
		borderColor: COLOR_LIGHT_GREY,
		width: '275px',
	},

	list: {
		flexGrow: 1,
	},

	schedule: {
		margin: '0 auto',
		whiteSpace: 'normal',
	},
}));
