import React, { useEffect, useMemo, useState } from 'react';
import './TwoByTwo.scss';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Text } from '@visx/text';
import { scaleLinear } from '@visx/scale';
import { Grid } from '@visx/grid';
import { ScaleLinear } from 'd3-scale';
import { DataParser, DataRecord } from 'typings/app';
import { areRecordsValid, buildDataPoint, getGraphDomain } from 'utils/graphs';
import { defaultPadding, heightWithinPadding, Padding, widthWithinPadding } from 'theme/padding';
import { COLOR_LIGHT_PINK, COLOR_MIDDLE_BLUE } from 'theme/colors';
import { AXIS_LABEL_SIZE, AxisProps, defaultAxisProps } from '../AxisProps';
import { MediaMentionData } from '../../MediaMention/MediaMention';
import useAnnotation from '../../../hooks/useAnnotation';
import { Annotation } from '../../Annotation';
import MediaMentionModal from '../../MediaMention/MediaMentionModal';
import { Tooltip } from '../../Tooltip';
import TwoByTwoLabelsToggle, { TWO_BY_TWO_LABELS_TOGGLE_HEIGHT_PX } from '../TwoByTwoLabelsToggle/TwoByTwoLabelsToggle';
import { buildMediaMentions } from '../../../utils';

export interface TwoByTwoPoint {
	label: string;
	x: number;
	y: number;
	z?: any;
	mediaMentionData?: MediaMentionData;
	group?: string;
}

export type TwoByTwoColorFunction = (d: TwoByTwoPoint) => string;

export type TwoByTwoDotRadiusFunction = (d: TwoByTwoPoint) => number;

export interface TwoByTwoCrossbarPosition {
	x1: number;
	x2: number;
	y1: number;
	y2: number;
}

export interface TwoByTwoGraphSize {
	width: number;
	height: number;
}

export type TwoByTwoCrossbarPositionFunction = (
	graphSize: TwoByTwoGraphSize,
	xScale: ScaleLinear<number, number, never>,
	yScale: ScaleLinear<number, number, never>,
) => TwoByTwoCrossbarPosition;

export interface TwoByTwoProps {
	/**
   * Props for the left and bottom axes
   */
	axisProps?: AxisProps,

	/**
   * Function for computing the fill color of a dot
   */
	colorFn?: TwoByTwoColorFunction;

	horizontalCrossBarPosFn?: TwoByTwoCrossbarPositionFunction;

	verticalCrossBarPosFn?: TwoByTwoCrossbarPositionFunction;

	/**
   * Data to render
   */
	data?: TwoByTwoPoint[];

	/**
   * Number of data points to pad the X axis data by
   */
	dataPaddingX?: number;

	/**
   * Number of data points to pad the Y axis data by
   */
	dataPaddingY?: number;

	/**
   * Function for computing the radius of a dot
   */
	dotRadiusFn?: TwoByTwoDotRadiusFunction;

	/**
   * Outer height of the graph
   */
	height?: number;

	/**
   * Outer width of the graph
   */
	width?: number;

	/**
   * Padding around the graph
   */
	padding?: Padding;

	onClickElement?: (data: Record<string, any>) => void,
}

/**
 * Type alias for a click, mouseover, or mouseout event for a circle in the
 * two-by-two graph
 */
export type TwoByTwoCircleMouseEvent = React.MouseEvent<SVGCircleElement, MouseEvent>
& { target: HTMLElement }
& { target: { ownerSVGElement: SVGSVGElement } }

const GRAPH_PADDING = 8;

const TwoByTwo: React.FC<TwoByTwoProps> & DataParser = ({
	axisProps = defaultAxisProps,
	colorFn = () => 'black',
	data = [],
	dotRadiusFn = () => 8,
	height = 500,
	padding = defaultPadding,
	width = 500,
	onClickElement = () => {},
}) => {
	const graphWidth = widthWithinPadding(width - axisProps?.leftAxisWidthPx - AXIS_LABEL_SIZE, padding);
	const graphHeight = heightWithinPadding(
		height - axisProps?.bottomAxisHeightPx - AXIS_LABEL_SIZE -
		TWO_BY_TWO_LABELS_TOGGLE_HEIGHT_PX - GRAPH_PADDING, padding
	);

	const x = (d: TwoByTwoPoint) => d.x;
	const y = (d: TwoByTwoPoint) => d.y;
	const label = (d: TwoByTwoPoint) => d.label;

	const xScale = useMemo(() => {
		return scaleLinear({
			range: [0, graphWidth],
			domain: getGraphDomain(data.map(x), 0.10),
		});
	}, [data, x]);

	const yScale = useMemo(() => {
		return scaleLinear({
			range: [graphHeight, 0],
			domain: getGraphDomain(data.map(y)),
		});
	}, [data, y]);

	const xPoint = (d: TwoByTwoPoint) => xScale(x(d));
	const yPoint = (d: TwoByTwoPoint) => yScale(y(d));

	const {
		currentEleRef,
		annotationIsOpen,
		showAnnotation,
		hideAnnotation,
		annotationData,
	} = useAnnotation<TwoByTwoPoint>();

	useEffect(() => {
		if (annotationIsOpen) {
			onClickElement({ type: 'point', data: annotationData });
		}
	}, [annotationIsOpen]);

	const [showAllLabels, setShowAllLabels] = useState<boolean>(false);

	useEffect(() => {
		if (showAllLabels) {
			onClickElement({ type: 'show_labels' });
		}
	}, [showAllLabels]);

	return (
		<div
			className='TwoByTwo'
			style={{
				height,
				width,
			}}
		>
			<div
				className='TwoByTwoLabelsToggle__wrapper'
				style={{
					paddingRight: padding?.right,
					paddingTop: padding?.top,
					width,
				}}
			>
				<TwoByTwoLabelsToggle
					isActive={showAllLabels}
					onClick={() => setShowAllLabels(!showAllLabels)}
				/>
			</div>

			<svg
				className='TwoByTwo__svg'
				height={height - TWO_BY_TWO_LABELS_TOGGLE_HEIGHT_PX}
				width={width}
			>
				<Group
					className='TwoByTwo__content-wrapper'
					left={AXIS_LABEL_SIZE + axisProps?.leftAxisWidthPx + padding?.left}
					top={GRAPH_PADDING}
					height={graphHeight}
					width={graphWidth - axisProps?.leftAxisWidthPx}
				>
					<Grid
						className='TwoByTwo__grid'
						xScale={xScale}
						yScale={yScale}
						width={graphWidth}
						height={graphHeight}
						stroke='#e5e5e5'
						strokeDasharray={'6 6'}
					/>

					<AxisLeft
						axisClassName='TwoByTwo__AxisLeft'
						scale={yScale}
						tickFormat={axisProps?.leftAxisTickFormatter}
						label={axisProps?.leftAxisLabel}
						labelOffset={70}
						labelClassName={'TwoByTwo__AxisLeft-label'}
						stroke={'#e5e5e5'}
						strokeWidth={3}
					/>

					<AxisBottom
						axisClassName='TwoByTwo__AxisBottom'
						numTicks={axisProps?.bottomAxisNumTicks}
						scale={xScale}
						top={graphHeight}
						tickFormat={axisProps?.bottomAxisTickFormatter}
						label={axisProps?.bottomAxisLabel}
						labelOffset={30}
						labelClassName={'TwoByTwo__AxisBottom-label'}
						stroke={'#e5e5e5'}
						strokeWidth={3}
					/>

					<Group
						className='TwoByTwo__content'
						height={graphHeight}
						width={graphWidth}
					>
						{data.map((d, i) => {
							const circleFill = d.mediaMentionData && d.mediaMentionData.insights
								? COLOR_LIGHT_PINK
								: colorFn(d);

							return (
								<Group
									key={`TwoByTwo__dot-group-${d.label}-${i}`}
								>
									{showAllLabels && (
										<Text
											fontSize={14}
											fontWeight={700}
											textAnchor='middle'
											x={xPoint(d)}
											y={yPoint(d) - 24}
										>{label(d)}</Text>
									)}

									<Tooltip
										title={
											<React.Fragment>
												<div>
													<strong>{label(d)}</strong>
												</div>
											</React.Fragment>
										}
										placement='top'
										disableHoverListener={showAllLabels}
										disableFocusListener
									>
										<circle
											className='TwoByTwo__dot'
											fill={circleFill}
											opacity={0.7}
											cx={xPoint(d)}
											cy={yPoint(d)}
											r={dotRadiusFn(d)}
											onClick={(e: TwoByTwoCircleMouseEvent) => {
												if (!d.mediaMentionData) {
													return;
												}
												showAnnotation({
													referenceEle: e.target,
													annotationData: d,
												});
											}}
										/>
									</Tooltip>
								</Group>
							);
						})}
					</Group>
				</Group>
			</svg>

			{annotationIsOpen && annotationData && annotationData.mediaMentionData && (
				<Annotation
					isShowing
					onClickClose={() => hideAnnotation()}
					targetEle={currentEleRef}
				>
					<MediaMentionModal
						numMentions={annotationData.mediaMentionData.numMentions}
						insights={annotationData.mediaMentionData.insights}
						mediaMentions={annotationData.mediaMentionData.mediaMentions}
						term={annotationData.mediaMentionData.term}
					>
						<div>
							<em>{axisProps?.bottomAxisLabel}</em>: {annotationData.x}
						</div>
						<div>
							<em>{axisProps?.leftAxisLabel}</em>: {annotationData.y}
						</div>
					</MediaMentionModal>
				</Annotation>
			)}
		</div>
	);
};

TwoByTwo.dataParser = (records: DataRecord[], metadata = {}) => {
	const areValid = areRecordsValid(records, metadata);

	if (!areValid) return {};

	const { xAxis, yAxis } = metadata;

	const componentData: TwoByTwoPoint[] = records.map(record => {
		const dataPoint = buildDataPoint(record, metadata);

		const mediaMentions = buildMediaMentions(record);

		return {
			...dataPoint,
			mediaMentionData: {
				insights: record?.Insights ?? '',
				mediaMentions: mediaMentions,
				numMentions: mediaMentions.length,
				term: dataPoint.label,
			},
		};
	});

	const componentProps: TwoByTwoProps = {
		axisProps: {
			...defaultAxisProps,
			leftAxisLabel: yAxis?.column,
			bottomAxisLabel: xAxis?.column,
			bottomAxisHeightPx: 40,
		},
		colorFn: () => COLOR_MIDDLE_BLUE,
		dotRadiusFn: () => 8,
		// dataPaddingY: 0.5,
		padding: {
			bottom: 40,
			left: 40,
			right: 80,
			top: 40,
		},
		horizontalCrossBarPosFn: (graphSize, xScale, yScale) => {
			return {
				x1: 0,
				x2: graphSize.width,
				y1: yScale(0),
				y2: yScale(0),
			};
		},
		verticalCrossBarPosFn: (graphSize, xScale) => {
			return {
				x1: xScale(3329),
				x2: xScale(3329),
				y1: 0,
				y2: graphSize.height,
			};
		},
	};

	return { componentData, componentProps };
};

export default TwoByTwo;
