import React from 'react';
import './MediaMentionModal.scss';
import { MediaMentionData } from './MediaMention';

const MediaMentionModal: React.FC<MediaMentionData> = ({
	children,
	insights,
	mediaMentions,
	numMentions,
	term,
}) => {
	numMentions = numMentions || 0;

	if (typeof term === 'object') {
		let flatTerms = '';
		for (const [key, val] of Object.entries(term)) {
			flatTerms += `${key}: ${val}<br>`;
		}
		term = flatTerms;
	}

	return (
		<div className='MediaMentionModal'>
			<div className='MediaMentionModal__header'>
				<h3 className='MediaMentionModal__header__term' dangerouslySetInnerHTML={{ __html: term }} />
				{numMentions > 0 && (
					<div className='MediaMentionModal__header__num-mentions'>
						{
							numMentions > 1 ? (
								`${numMentions} mentions`
							) : (
								'1 mention'
							)
						}
					</div>
				)}
				{children && (
					<div className='MediaMentionModal__header__details'>
						{children}
					</div>
				)}
				{insights.trim() !== '' && (
					<div className='MediaMentionModal__header__insights'>
						<h4 className='MediaMentionModal__header__insights-title'>Insights:</h4>
						<p className='MediaMentionModal__header__insights-content'>{insights.trim()}</p>
					</div>
				)}
			</div>
			{mediaMentions.length > 0 && (
				<div className='MediaMentionModal__media-mentions'>
					<h4 className='MediaMentionModal__media-mentions-title'>Media Mentions:</h4>
					<ul className='MediaMentionModal__media-mentions-list'>
						{mediaMentions.map((mediaMention, idx) => (
							<li className='MediaMentionModal__media-mentions-item' key={`media-mention-${idx}`}>
								<h5 className='MediaMentionModal__media-mentions-item-publisher-name'>
									{mediaMention.url !== undefined ? (
										<a
											href={mediaMention.url}
											rel='noreferrer external'
											target='_blank'
										>{mediaMention.publisherName.trim()}</a>
									) : (
										<span>{mediaMention.publisherName.trim()}</span>
									)}
								</h5>
								<p className='MediaMentionModal__media-mentions-item-content'>
									{mediaMention.content.trim()}
								</p>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default MediaMentionModal;

export const mediaMentionModalStoriesDefaultArgs = {
	insights: 'Delivery included in 4 of top 10 Tech/Implication linkages with multiple contexts such as: Drug Delivery w/ Genetic Engineering, and Drones & Food Delivery',
	mediaMentions: [
		{
			publisherName: 'Harvard Business Review',
			content: '"It\'s clear autonomous delivery provides a faster, cleaner, safer, cheaper and higher-quality alternative to road-based delivery."',
		},
		{
			publisherName: 'Business Insider',
			content: '"Amazon is starting to reap dividends from the enormous investments they have made in their automated delivery platform."',
		},
	],
	numMentions: 72,
	term: 'Delivery -> Automation',
};
