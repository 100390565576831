import { makeStyles } from '@material-ui/core/styles';
import { COLOR_DARK_BLUE, COLOR_LIGHT_PINK } from '../../theme';
import { JumpToMenuProps } from './JumpToMenu';
import { menuBorderWidth } from '../../theme/metrics';

export const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(0),
		minHeight: 205,
	},

	title: {
		fontFamily: 'Ubuntu',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '14px',
		color: COLOR_DARK_BLUE,
		lineHeight: (props: JumpToMenuProps) => props.isMenu ? '39px' : '28px',
		textTransform: (props: JumpToMenuProps) => props.isMenu ? 'uppercase' : undefined,
		marginTop: (props: JumpToMenuProps) => props.isMenu ? theme.spacing(14) : undefined,
		paddingLeft: (props: JumpToMenuProps) => props.isMenu ? theme.spacing(9) : undefined,
	},

	list: {
		margin: theme.spacing(2, 0, 0, 0),
		overflow: 'hidden',

		'& > li': {
			paddingLeft: (props: JumpToMenuProps) => props.isMenu ? undefined : theme.spacing(4),

			'&:hover': {
				background: (props: JumpToMenuProps) => props.isMenu ? `${COLOR_LIGHT_PINK}10` : undefined,
			},

			'&::before': {
				content: (props: JumpToMenuProps) => props.isMenu ? '' : '"\\2022"',
				color: COLOR_LIGHT_PINK,
				fontWeight: 'bold',
				display: 'inline-block',
				width: '20px',
				marginLeft: '-15px',
				verticalAlign: 'middle',
				fontSize: '20px',
				lineHeight: 0,
			},

			'& a': {
				textDecoration: 'none',
				color: COLOR_DARK_BLUE,
				verticalAlign: 'middle',
				fontSize: (props: JumpToMenuProps) => props.isMenu ? '14px' : undefined,
				lineHeight: (props: JumpToMenuProps) => props.isMenu ? '39px' : '28px',
				display: (props: JumpToMenuProps) => props.isMenu ? 'block' : undefined,
				paddingLeft: (props: JumpToMenuProps) => props.isMenu ? theme.spacing(9) - menuBorderWidth : undefined,
				borderLeft: `${menuBorderWidth}px solid transparent`,
				overflow: 'hidden',
				textOverflow: 'ellipsis',

				'&.active': {
					borderColor: (props: JumpToMenuProps) => props.isMenu ? COLOR_LIGHT_PINK : undefined,
				},
			},

			'&.active a': {
				borderColor: (props: JumpToMenuProps) => props.isMenu ? COLOR_LIGHT_PINK : undefined,
			},
		},
	},

	loading: {
		fontSize: (props: JumpToMenuProps) => props.isMenu ? '14px' : undefined,
		lineHeight: (props: JumpToMenuProps) => props.isMenu ? '39px' : '28px',
		display: (props: JumpToMenuProps) => props.isMenu ? 'block' : undefined,
		paddingLeft: (props: JumpToMenuProps) => props.isMenu ? theme.spacing(9) : undefined,
	},
}));
