import React, { useEffect, useCallback, useState } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { PickD3Scale, scaleBand, scaleLinear } from '@visx/scale';
import { BarRounded } from '@visx/shape';
import { TextProps } from '@visx/text/lib/Text';
import { Text } from '@visx/text';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { buildBarGraphPoint, buildBarGraphProps } from 'utils/graphs';
import { defaultPadding, heightWithinPadding, Padding, widthWithinPadding } from 'theme/padding';
import { COLOR_DARK_BLUE, COLOR_LIGHT_BLUE } from 'theme/colors';
import useAnnotation from 'hooks/useAnnotation';
import { DataParser, DataRecord } from 'typings/app';
import { Annotation } from '../../Annotation';
import MediaMentionModal from '../../MediaMention/MediaMentionModal';
import { MediaMentionData } from '../../MediaMention/MediaMention';
import { AXIS_LABEL_SIZE, AxisProps, defaultAxisProps } from '../AxisProps';
import './BarGraph.scss';

/**
 * Function to derive the CSS color for a bar representing a given data point
 */
export type BarGraphColorFunction = (d: BarGraphPoint) => string;

/**
 * The default color function maps values >= 0 to a blue color, and values < 0
 * to a red color.
 *
 * @param d The data point
 * @returns The color to render
 */
export const defaultColorFn: BarGraphColorFunction = (
	d: BarGraphPoint
): string => {
	if (d.value >= 0) {
		return COLOR_LIGHT_BLUE;
	}

	return COLOR_DARK_BLUE;
};

/**
 * A single data point for a bar graph
 */
export interface BarGraphPoint {
	/**
	 * The label for this data point. Used to populate the X axis.
	 */
	label: string;

	/**
	 * The value for this data point. Used to populate the Y axis.
	 */
	value: number;

	mediaMentionData?: MediaMentionData;

	/**
	 * Arbitrary grouping information for things like applying colors for each
	 * group, handling legend interactions, etc.
	 */
	group?: string;
}

export interface BarGraphProps {
	/**
	 * Props for the left and bottom axes
	 */
	axisProps?: AxisProps;

	/**
	 * Function for picking the bar color for a given datapoint
	 */
	colorFn?: BarGraphColorFunction;

	/**
	 * Data to render
	 */
	data?: BarGraphPoint[];

	/**
	 * Number of data points to leave between the highest bars and the top edge of
	 * the graph
	 */
	Top?: number;

	/**
	 * Number of data points to leave between the lowest bars and the bottom edge
	 * of the graph
	 */
	Bottom?: number;

	/**
	 * Outer height of the graph
	 */
	height?: number;

	/**
	 * Outer width of the graph
	 */
	width?: number;

	/**
	 *  around the graph
	 */
	padding?: Padding

	/**
	 *  for the X scale
	 */
	xScalePadding?: number;

	/**
	 * Event handler for a mouseover event for a bar in the graph
	 */
	onBarMouseOver?: (e: BarGraphBarMouseEvent, d: BarGraphPoint) => void;

	/**
	 * Event handler for a mouseout event for a bar in the graph
	 */
	onBarMouseOut?: (e: BarGraphBarMouseEvent, d: BarGraphPoint) => void;

	/**
	 * Event handler for a click event for a bar in the graph
	 */
	onBarClick?: (e: BarGraphBarMouseEvent, d: BarGraphPoint) => void;

	/**
	 * Whether to show a legend
	 */
	showLegend?: boolean;

	/**
	 * Ordinal scale for determining the colors of the legend elements
	 */
	legendColorScale?: PickD3Scale<'ordinal', any, any>;

	/**
	 * Height of the legend
	 */
	legendHeight?: number;

	onClickElement?: (data: Record<string, any>) => void,

	dataPaddingTop?: number;
	dataPaddingBottom?: number;
}

/**
 * Type alias for a click, mouseover, or mouseout event for a bar in the bar graph
 */
export type BarGraphBarMouseEvent =
	React.MouseEvent<SVGPathElement, MouseEvent>
	& {target: HTMLElement}
	& {target: {ownerSVGElement: SVGSVGElement}};

/**
 * A bar graph with a numerical Y axis, and a string X axis
 */
const BarGraph: React.FC<BarGraphProps> & DataParser = ({
	axisProps = defaultAxisProps,
	colorFn = defaultColorFn,
	data = [],
	dataPaddingTop = 0.05,
	dataPaddingBottom = 0,
	height = 500,
	width = 500,
	padding = defaultPadding,
	xScalePadding = 0.4,
	onBarClick = () => {},
	onBarMouseOver = () => {},
	onBarMouseOut = () => {},
	showLegend = false,
	legendHeight = 36,
	legendColorScale,
	onClickElement = () => {},
}) => {
	const computedLegendHeight = !showLegend ? 0 : legendHeight;
	const graphWidth = widthWithinPadding(
		width - axisProps?.leftAxisWidthPx - AXIS_LABEL_SIZE,
		padding
	);
	const graphHeight = heightWithinPadding(
		height -
		computedLegendHeight -
		axisProps?.bottomAxisHeightPx -
		AXIS_LABEL_SIZE,
		padding
	);

	// We'll make some helpers to get at the data we want
	const x = (d: BarGraphPoint) => d.label;
	const y = (d: BarGraphPoint) => d.value;

	// And then scale the graph by our data
	const xScale = scaleBand({
		range: [0, graphWidth],
		round: true,
		domain: data.map(x),
		padding: xScalePadding,
	});

	const yMax = Math.max(...data.map(y));
	const yDomain: [number, number] = [
		data.map(y).reduce((acc, i) => {
			if (i < acc) {
				return i;
			}

			return acc;
		}, 0) - dataPaddingBottom,
		yMax + yMax * dataPaddingTop, // Calculate padding in Y based on a percentage
	];

	const yScale = scaleLinear({
		range: [graphHeight, 0],
		round: false,
		domain: yDomain,
	});

	const xPoint = (d: BarGraphPoint) => xScale(x(d));
	const yPoint = (d: BarGraphPoint) => yScale(y(d));

	const {
		currentEleRef,
		annotationIsOpen,
		showAnnotation,
		hideAnnotation,
		annotationData,
	} = useAnnotation<BarGraphPoint>();

	useEffect(() => {
		if (annotationIsOpen) {
			onClickElement({ type: 'bar', data: annotationData });
		}
	}, [annotationIsOpen]);

	const [hoveredBarData, setHoveredBarData] = useState<BarGraphPoint | null>(
		null
	);

	const bottomAxisProps = useCallback(
		(value: string, idx: number) => {
			const defaults = axisProps?.bottomAxisTickLabelProps(value, idx, []);

			const isShowingAnnotationForAxis =
				annotationIsOpen && value === (annotationData as BarGraphPoint).label;

			const isHoveredOverBarForAxis =
				!annotationIsOpen &&
				hoveredBarData &&
				value === (hoveredBarData as BarGraphPoint).label;

			const fontWeight =
				isShowingAnnotationForAxis || isHoveredOverBarForAxis ? 700 : 400;

			const newProps: Partial<TextProps> = {
				...defaults,
				fill:
					annotationIsOpen && value !== (annotationData as BarGraphPoint).label
						? '#aaa'
						: 'black',
				fontWeight,
			};

			return newProps;
		},
		[
			annotationData,
			annotationIsOpen,
			axisProps?.bottomAxisTickLabelProps,
			hoveredBarData,
		]
	);

	const leftAxisProps = useCallback(
		(value: any, idx: number) => {
			const defaults = axisProps?.leftAxisTickLabelProps(
				value,
				idx,
				[]
			) as Partial<TextProps>;

			const newProps: Partial<TextProps> = {
				...defaults,
				fill: annotationIsOpen ? '#aaa' : 'black',
			};

			return newProps;
		},
		[annotationIsOpen, axisProps?.leftAxisTickLabelProps]
	);

	const barFill = useCallback(
		(d: BarGraphPoint): string => {
			const isShowingAnnotationForBar =
				annotationIsOpen &&
				annotationData &&
				d.label === (annotationData as BarGraphPoint).label;

			const isHoveredOverBar =
				!annotationIsOpen &&
				hoveredBarData &&
				d.label === (hoveredBarData as BarGraphPoint).label;

			if (isShowingAnnotationForBar || isHoveredOverBar) {
				return '#E90A6D';
			}

			return colorFn(d);
		},
		[annotationData, annotationIsOpen, hoveredBarData]
	);

	const [disabledGroups, setDisabledGroups] = useState<string[]>([]);

	useEffect(() => {
		if (disabledGroups) {
			onClickElement({ type: 'legend' });
		}
	}, [disabledGroups]);

	return (
		<div
			className='BarGraph'
			style={{
				height: height,
				width: width,
			}}
		>
			{showLegend && legendColorScale && (
				<div
					className='BarGraph__legend'
					style={{
						height: computedLegendHeight,
						paddingBottom: padding?.bottom,
						paddingLeft: axisProps?.leftAxisWidthPx,
						width: width,
					}}
				>
					<LegendOrdinal scale={legendColorScale}>
						{labels => (
							<div className='BarGraph__legend-contents'>
								{labels.map((label, i) => {
									const labelIsDisabled =
										disabledGroups.indexOf(label.text) !== -1;

									return (
										<LegendItem
											className='BarGraph__legend-item'
											key={`BarGraph-legend-item-${i}`}
											onClick={() => {
												const newDisabledLines = !labelIsDisabled
													? disabledGroups.slice().concat(label.text)
													: disabledGroups.filter(line => line !== label.text);

												setDisabledGroups(newDisabledLines);
											}}
										>
											<svg height={16} width={16}>
												<circle
													cx={8}
													cy={8}
													fill={labelIsDisabled ? 'transparent' : label.value}
													stroke={label.value}
													strokeWidth={2}
													r={6}
												/>
											</svg>
											<LegendLabel className='BarGraph__legend-label'>
												{label.text}
											</LegendLabel>
										</LegendItem>
									);
								})}
							</div>
						)}
					</LegendOrdinal>
				</div>
			)}

			<div
				className='BarGraph__graph'
				style={{
					height: height - computedLegendHeight,
				}}
			>
				<svg
					className='BarGraph__svg'
					height={height - computedLegendHeight}
					width={width}
				>
					<rect
						className='BarGraph__background'
						x={0}
						y={0}
						width={width}
						height={height}
						fill='white'
					/>
					<Group height={graphHeight}>
						<Group
							className='BarGraph__content-wrapper'
							left={
								AXIS_LABEL_SIZE + axisProps?.leftAxisWidthPx + padding?.left
							}
							top={padding?.top}
							height={graphHeight}
							width={graphWidth - axisProps?.leftAxisWidthPx}
						>
							<Grid
								className='BarGraph__grid'
								xScale={xScale}
								yScale={yScale}
								width={graphWidth}
								height={graphHeight}
								numTicksColumns={axisProps?.bottomAxisNumTicks}
								stroke='black'
								strokeOpacity={0.1}
								xOffset={xScale.bandwidth() / 2}
							/>

							<Group
								className='BarGraph__content'
								height={graphHeight}
								width={graphWidth}
							>
								{data
									.filter(d => {
										if (!showLegend || d.group === undefined) {
											return true;
										}

										return disabledGroups.indexOf(d.group) === -1;
									})
									.map((d, i) => {
										const barHeight = Math.abs(yPoint(d) - yScale(0));
										const barYPos = yScale(Math.max(d.value, 0)) || 0;

										return (
											<Group key={`bar-${i}`}>
												<BarRounded
													className='BarGraph__bar'
													x={xPoint(d) || 0}
													y={barYPos}
													height={barHeight}
													width={xScale.bandwidth()}
													fill={barFill(d)}
													top={barYPos < yScale(0)}
													bottom={barYPos >= yScale(0)}
													radius={4}
													onClick={(e: BarGraphBarMouseEvent) => {
														onBarClick(e, d);

														if (!d.mediaMentionData) {
															return;
														}

														showAnnotation({
															referenceEle: e.target,
															annotationData: d,
														});

													}}
													onMouseOver={(e: BarGraphBarMouseEvent) => {
														onBarMouseOver(e, d);

														if (annotationIsOpen || !d.mediaMentionData) {
															return;
														}

														setHoveredBarData(d);
													}}
													onMouseOut={(e: BarGraphBarMouseEvent) => {
														setHoveredBarData(null);

														onBarMouseOut(e, d);
													}}
												/>
											</Group>
										);
									})}
							</Group>

							<AxisLeft
								axisClassName='BarGraph__AxisLeft'
								hideAxisLine
								scale={yScale}
								tickFormat={axisProps?.leftAxisTickFormatter}
								tickLabelProps={leftAxisProps}
								tickStroke={
									annotationIsOpen ? '#aaa' : axisProps?.leftAxisTickStroke
								}
								label={axisProps?.leftAxisLabel}
								labelOffset={70}
								labelClassName={'BarGraph__AxisLeft-label'}
							/>

							<AxisBottom
								axisClassName='BarGraph__AxisBottom'
								numTicks={axisProps?.bottomAxisNumTicks}
								scale={xScale}
								top={graphHeight}
								tickFormat={axisProps?.bottomAxisTickFormatter}
								tickLabelProps={bottomAxisProps}
								tickStroke={
									annotationIsOpen ? '#aaa' : axisProps?.bottomAxisTickStroke
								}
								label={axisProps?.bottomAxisLabel}
								labelOffset={115}
								labelClassName={'BarGraph__AxisBottom-label'}
							/>
						</Group>
					</Group>
				</svg>

				{annotationIsOpen && annotationData && annotationData.mediaMentionData && (
					<Annotation
						isShowing
						onClickClose={() => hideAnnotation()}
						targetEle={currentEleRef}
					>
						<MediaMentionModal
							insights={annotationData.mediaMentionData.insights}
							mediaMentions={annotationData.mediaMentionData.mediaMentions}
							numMentions={annotationData.mediaMentionData.numMentions}
							term={annotationData.mediaMentionData.term}
						/>
					</Annotation>
				)}
			</div>
		</div>
	);
};

BarGraph.dataParser = (records: DataRecord[], metadata = {}) => {
	const componentData: BarGraphPoint[] = records.map((record: DataRecord): BarGraphPoint => {
		return buildBarGraphPoint(record, metadata);
	});

	const componentProps = buildBarGraphProps(metadata);

	return { componentData, componentProps };
};

export default BarGraph;
