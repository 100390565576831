import { debounce } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { GetMentionableUsers } from 'services';
import {
	AutoCompleteTextarea,
	ChatAutoCompleteProps,
	CommandItem,
	EmoticonItem,
	LoadingIndicator,
	useChannelContext,
	useChatContext,
	UserItem,
} from 'stream-chat-react';
import { Mentionable } from 'typings/cms';
import { useChat } from './ChatProvider';

export const ChatAutoComplete: React.FC<ChatAutoCompleteProps> = props => {
	const {
		additionalTextareaProps,
		commands,
		disabled,
		disableMentions,
		grow,
		handleSubmit,
		innerRef,
		maxRows,
		onChange,
		onFocus,
		onPaste,
		onSelectItem,
		placeholder,
		rows = 3,
		SuggestionItem,
		SuggestionList,
		triggers,
		value,
	} = props;

	const { emojiConfig } = useChannelContext();
	const { mutes } = useChatContext();

	const { streamChat } = useChat();

	const [searching, setSearching] = useState(false);

	const { emojiData, EmojiIndex } = emojiConfig || {};

	const emojiIndex = useMemo(() => {
		if (EmojiIndex) {
			return new EmojiIndex(emojiData);
		}
		return null;
	}, [emojiData, EmojiIndex]);

	const emojiReplace = (word: string) => {
		const found = emojiIndex?.search(word) || [];
		const emoji = found
			.filter(Boolean)
			.slice(0, 10)
			.find(({ emoticons }: any) => !!emoticons?.includes(word));
		if (!emoji || !('native' in emoji)) return null;
		return emoji.native;
	};

	const queryUsers = async (
		query: string,
		onReady: (users: Mentionable[]) => void
	) => {
		if (!query || searching) return;
		setSearching(true);

		const currentUser = streamChat.getCurrentUser();

		try {
			const users = await GetMentionableUsers({ accessToken: currentUser?.apiToken });

			console.log('users', users);

			if (onReady && users.length) {
				onReady(users);
			} else {
				onReady([]);
			}
		} catch (error) {
			console.log({ error });
		}

		setSearching(false);
	};

	const queryUsersDebounced = debounce(queryUsers, 200);

	const getTriggers = useCallback(
		() =>
			triggers || {
				'/': {
					component: CommandItem,
					dataProvider: (
						query: string,
						text: string,
						onReady: (result: any[], query: string) => void
					) => {
						if (text.indexOf('/') !== 0 || !commands) {
							return [];
						}
						const selectedCommands = commands.filter(
							command => command.name?.indexOf(query) !== -1
						);

						// sort alphabetically unless the you're matching the first char
						selectedCommands.sort((a, b) => {
							let nameA = a.name?.toLowerCase();
							let nameB = b.name?.toLowerCase();
							if (nameA?.indexOf(query) === 0) {
								nameA = `0${nameA}`;
							}
							if (nameB?.indexOf(query) === 0) {
								nameB = `0${nameB}`;
							}
							// Should confirm possible null / undefined when TS is fully implemented
							if (nameA != null && nameB != null) {
								if (nameA < nameB) {
									return -1;
								}
								if (nameA > nameB) {
									return 1;
								}
							}

							return 0;
						});

						const result = selectedCommands.slice(0, 10);
						if (onReady) onReady(result, query);

						return result;
					},
					output: (entity: Record<string, any>) => ({
						caretPosition: 'next',
						key: entity.name,
						text: `/${entity.name}`,
					}),
				},
				':': {
					component: EmoticonItem,
					dataProvider: (
						query: string,
						_: any,
						onReady: (result: any[], query: string) => void
					) => {
						if (
							query.length === 0 ||
							query.charAt(0).match(/[^a-zA-Z0-9+-]/)
						) {
							return [];
						}
						const emojis = emojiIndex?.search(query) || [];
						// emojiIndex.search sometimes returns undefined values, so filter those out first
						const result = emojis.filter(Boolean).slice(0, 10);
						if (onReady) onReady(result, query);

						return result;
					},
					output: (entity: Record<string, any>) => ({
						caretPosition: 'next',
						key: entity.id,
						text: `${'native' in entity ? entity.native : ''}`,
					}),
				},
				'@': {
					callback: (item: any) => onSelectItem && onSelectItem(item),
					component: UserItem,
					dataProvider: (
						query: string,
						_: any,
						onReady: (result: any[], query: string) => void
					) => {
						return queryUsersDebounced(
							query,
							(data: (Mentionable | undefined)[]) => {
								if (onReady) onReady(data, query);
							}
						);
					},
					output: (entity: Record<string, Mentionable>) => ({
						caretPosition: 'next',
						key: entity.id,
						text: `@${entity.name || entity.id}`,
					}),
				},
			},
		[
			commands,
			onSelectItem,
			triggers,
			emojiIndex,
		]
	);

	const updateInnerRef = useCallback(
		ref => {
			if (innerRef) {
				innerRef.current = ref;
			}
		},
		[innerRef]
	);

	return (
		<AutoCompleteTextarea
			additionalTextareaProps={additionalTextareaProps}
			className='str-chat__textarea__textarea'
			containerClassName='str-chat__textarea'
			disabled={disabled}
			disableMentions={disableMentions}
			dropdownClassName='str-chat__emojisearch'
			grow={grow}
			handleSubmit={handleSubmit}
			innerRef={updateInnerRef}
			itemClassName='str-chat__emojisearch__item'
			listClassName='str-chat__emojisearch__list'
			loadingComponent={LoadingIndicator}
			maxRows={maxRows}
			minChar={0}
			mutes={mutes}
			onChange={onChange}
			onFocus={onFocus}
			onPaste={onPaste}
			placeholder={placeholder}
			replaceWord={emojiReplace}
			rows={rows}
			SuggestionItem={SuggestionItem}
			SuggestionList={SuggestionList}
			trigger={getTriggers()}
			value={value}
		/>
	);
};
