import { DataRecord } from 'typings/app';
import { Metadata } from 'typings/cms';
import { scaleOrdinal } from '@visx/scale';
import {
	BarGraphColorFunction,
	BarGraphPoint,
	BarGraphProps,
} from 'components/Graphs/BarGraph/BarGraph';
import { defaultAxisProps } from 'components/Graphs/AxisProps';
import { SpeciateMVPColors } from 'theme/colors';
import { buildMediaMentions } from './mediaMentions';

const parsers = {
	integer: (value: string): number => parseInt(value, 10),
	int: (value: string): number => parseInt(value, 10),
	float: (value: string): number => parseFloat(value),
};

interface DataPoint {
	label: string;
	y: number;
	x: number;
	group?: string;
}

interface DataPointGroup {
	a: DataPoint;
	b: DataPoint;
}

export const buildDataPoints = (
	recordA: DataRecord,
	recordB: DataRecord,
	metadata: Metadata
): DataPointGroup => {
	const { xAxis, yAxis, label } = metadata;

	if (!yAxis.columns) throw new Error('Invalid data');

	const [columnA, columnB] = yAxis.columns;

	const pointA = buildDataPoint(recordA, {
		label,
		yAxis: { column: columnA, type: yAxis.type },
		xAxis,
	});

	const pointB = buildDataPoint(recordB, {
		label,
		yAxis: { column: columnB, type: yAxis.type },
		xAxis,
	});

	return {
		a: { ...pointA, group: columnA },
		b: { ...pointB, group: columnB },
	};
};

export const buildDataPoint = (
	record: DataRecord,
	metadata: Metadata
): DataPoint => {
	const { xAxis, yAxis, label } = metadata;

	const xValue = record[xAxis?.column];
	const yValue = record[yAxis?.column];

	const labelValue = String(record[label.column]).trim();

	return {
		label: labelValue,
		x: parsers[xAxis?.type ?? 'integer'](xValue),
		y: parsers[yAxis?.type ?? 'integer'](yValue),
	};
};

const isUndefined = (value: any) => typeof value === 'undefined';

export const areRecordsValid = (
	records: DataRecord[],
	metadata: Metadata
): boolean => {
	const firstRecord = records[0] ?? null;

	if (isUndefined(firstRecord)) return false;

	const { xAxis, yAxis, label } = metadata || {};

	if (
		isUndefined(firstRecord[xAxis.column]) ||
		isUndefined(firstRecord[label.column])
	) {
		return false;
	}

	if (yAxis.columns) {
		const [columnA, columnB] = yAxis.columns;

		if (
			isUndefined(firstRecord[columnA]) ||
			isUndefined(firstRecord[columnB])
		) {
			return false;
		}
	} else if (isUndefined(firstRecord[yAxis.column])) {
		return false;
	}

	return true;
};

export const buildBarGraphPoint = (record: DataRecord, metadata: Metadata): BarGraphPoint => {
	const valueColumn = metadata.groupColumns?.filter(col => record[col] != '').pop();

	if (typeof valueColumn === 'undefined') {
		throw new Error('bad record');
	}

	const mediaMentions = buildMediaMentions(record);

	return {
		label: record[metadata.xAxis.column],
		value: record[valueColumn],
		group: valueColumn,
		mediaMentionData: {
			insights: record.Insights.trim(),
			mediaMentions: mediaMentions,
			numMentions: mediaMentions.length,
			term: record[metadata.xAxis.column].trim(),
		},
	};
};

export const buildBarGraphProps = (metadata: Metadata): BarGraphProps => {
	const colorFn: BarGraphColorFunction = (d: BarGraphPoint): string => {
		const highlightIdx = 1;
		const defaultIdx = 2;

		if (d.group === undefined || metadata.groupColumns === undefined) {
			return SpeciateMVPColors[defaultIdx];
		}

		const highlights = Array.isArray(metadata.highlights)
			? metadata.highlights.map(h => h.toLowerCase())
			: [];
		if (highlights.indexOf(d.label.toLowerCase()) !== -1) {
			return SpeciateMVPColors[highlightIdx];
		}

		const idx = metadata.groupColumns.indexOf(d.group);

		return SpeciateMVPColors[idx === -1 ? defaultIdx : idx];
	};

	return {
		axisProps: {
			...defaultAxisProps,
			bottomAxisHeightPx: 100,
			bottomAxisLabel: String(metadata.xAxis.label),
			leftAxisLabel: String(metadata.yAxis.label),
		},
		colorFn,
		showLegend: metadata.showLegend ?? true,
		legendColorScale: scaleOrdinal({
			domain: metadata.groupColumns,
			range: SpeciateMVPColors,
		}),
		padding: {
			bottom: 40,
			left: 40,
			right: 40,
			top: 40,
		},
	};
};

export const getGraphDomain = (
	points: number[],
	incrementor = 0.2
): number[] => {
	const min = Math.min(...points);
	const max = Math.max(...points);

	return [min - Math.abs(min) * incrementor, max + max * incrementor];
};
