import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		flexGrow: 1,
		paddingTop: theme.spacing(6),
		paddingRight: theme.spacing(6),
		paddingBottom: theme.spacing(6),
		paddingLeft: theme.spacing(6),
	},
	title: {
		marginBottom: theme.spacing(0),
	},
	commentInfoCard: {
		position: 'absolute',
		top: theme.spacing(6),
		right: theme.spacing(6),
		zIndex: 10,
		cursor: 'pointer',
		width: 25,

		'& img': {
			width: 25,
			minWidth: 25,
		},

		'& p': {
			position: 'absolute',
			top: '0px',
			width: '25px',
			fontWeight: 'bold',
			fontSize: '13px',
			lineHeight: '20px',
			zIndex: 1199,
			textAlign: 'center',
			color: theme.palette.common.white,
		},
	},
	scrollableCardWrapper: {
		overflowY: 'auto',
	},
	subtitle: {
		display: 'block',
		marginTop: '15px',
		lineHeight: 1.5,
	},
	description: {
		display: 'block',
		marginTop: '15px',
		lineHeight: 1.5,
		position: 'relative',
		zIndex: 2,
	},
}));
