import { default as stringHash } from 'string-hash';

let lastId = 0;

export function uniqueElementID(prefix = 'uniqueElementID_'): string {
	lastId++;
	return `${prefix}${lastId}`;
}

export function hashCode(str: string): string {
	return stringHash(str).toString();
}
