import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	container: {
		position: 'fixed',
		left: 0,
		alignItems: 'baseline',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
		padding: theme.spacing(0, 16),
		background: theme.palette.text.primary,
		color: theme.palette.common.white,
		zIndex: 999999,
	},
	content: {
		flex: '1 0 300px',
		margin: 15,

		'& > a': {
			color: theme.palette.secondary.main,
		},
	},
	acceptButton: {
		background: theme.palette.secondary.main,
		border: 0,
		borderRadius: 0,
		boxShadow: 'none',
		color: theme.palette.common.white,
		cursor: 'pointer',
		flex: '0 0 auto',
		padding: '5px 10px',
		margin: '15px',
	},
	declineButton: {
		background: 'none',
		cursor: 'pointer',
		border: 0,
		borderRadius: 0,
		boxShadow: 'none',
		flex: '0 0 auto',
		padding: '5px 10px',
		margin: '15px',
		color: theme.palette.common.white,
	},
}));
