import { useRef, useEffect, useState } from 'react';

const PREFIX = 'Speciate';

export function useDocumentTitle(initialTitle?: string): {title?: string, setTitle: (title: string) => void} {
	const [title, setTitle] = useState(initialTitle);

	const prevTitle = useRef(document.title);

	useEffect(() => {
		if (title) {
			document.title = `${PREFIX} :: ${title}`;
		}

		return () => {
			document.title = prevTitle.current;
		};
	}, [title]);

	return { title, setTitle };
}

