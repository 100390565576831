import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Typography } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Container from '@material-ui/core/Container';
import { useDocumentTitle } from 'hooks';
import { useStore } from 'stores';
import { Landscape } from 'typings/cms';
import { TENANT_MANIFOLD } from 'constants/tenants';
import { getLandscapes } from '../../services';
import { useAvatarGroupStyles, useStyles } from './styles';
import './Home.scss';
import { useAuthentication } from '../../authentication';

export const getLandscapeTitle = (landscape: Landscape, tenantId?: string): string => {
	return `${tenantId === TENANT_MANIFOLD ? `${landscape?.client?.display} :: ` : ''}${landscape?.title}`;
};

export const Home: React.FC = observer(() => {
	useDocumentTitle('Landscapes');

	const { currentUser } = useAuthentication();
	const { appStore } = useStore();
	const history = useHistory();
	const classes = useStyles();
	const avatarClasses = useAvatarGroupStyles();

	const accessToken = currentUser?.apiToken;

	const { isLoading, isError, data } = useQuery(
		'landscapes',
		() => getLandscapes({ accessToken }),
		{
			// The query will not execute until the accessToken exists
			enabled: !!accessToken,
		}
	);

	const handleLandscapeClick = useCallback((id) => {
		history.push(`/landscape/${id}`);
	}, []);

	const isEmpty = !isLoading && !isError && (data?.length ?? 0) === 0;

	return (
		<div>
			<Container maxWidth='lg' className={classes.container}>
				<Typography variant='h6' className={classes.sectionTitle}>Landscapes</Typography>
				{isLoading && (
					<Typography variant='body2'>Loading...</Typography>
				)}
				{isError && (
					<div className={classes.error}>
						<Typography variant='body2'>Error while fetching the landscapes.</Typography>
					</div>
				)}
				<Grid container spacing={8}>
					{isEmpty ? (
						<Grid item xs={12}>
							<Typography variant='subtitle1' color='textSecondary'>No landscapes to show at the moment</Typography>
						</Grid>
					) : (
						(data || []).map((landscape, idx) => (
							<Grid key={`landscape-item-${idx}`} item xs={4}>
								<Paper
									className={classes.paper}
									onClick={handleLandscapeClick.bind(null, landscape.id)}
								>
									<Box
										display='flex'
										flex={1}
										alignItems='center'
										flexWrap='nowrap'
										mb={2}
									>
										<Typography
											color='textPrimary'
											variant='subtitle1'
											className={classes.landscapeTitle}
											noWrap
										>{getLandscapeTitle(landscape, currentUser?.tenantId)}</Typography>
										{appStore.showDemoableItems && (
											<span className={classes.customFlag}>Custom</span>
										)}
									</Box>

									{landscape.description && (
										<Typography
											variant='body2'
											color='textSecondary'
											className={classes.landscapeDescription}
										>{landscape.description}</Typography>
									)}

									<Box mt={7} display='flex' flex={1} alignItems='center'>
										<Box display='flex' flex={1} alignItems='center' className={classes.landscapeCreated}>
											<AccessTimeIcon />
											<Typography variant='body2'>
												{formatDistance(
													landscape.modified,
													new Date(),
													{ addSuffix: true }
												)}</Typography>
										</Box>

										{appStore.showDemoableItems && (
											<AvatarGroup max={4} classes={avatarClasses}>
												<Avatar alt='Remy Sharp' src='https://randomuser.me/api/portraits/men/66.jpg' />
												<Avatar alt='Travis Howard' src='https://randomuser.me/api/portraits/men/3.jpg' />
												<Avatar alt='Cindy Baker' src='https://randomuser.me/api/portraits/women/76.jpg' />
												<Avatar alt='Agnes Walker' src='https://randomuser.me/api/portraits/women/26.jpg' />
												<Avatar alt='Trevor Henderson' src='https://randomuser.me/api/portraits/men/76.jpg' />
											</AvatarGroup>
										)}
									</Box>
								</Paper>
							</Grid>
						))
					)}
				</Grid>
			</Container>
		</div>
	);
});
