import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		paddingTop: theme.spacing(6),
		paddingRight: theme.spacing(6),
		paddingBottom: theme.spacing(6),
		paddingLeft: theme.spacing(6),
	},

	title: {
		marginBottom: theme.spacing(7),
	},

	listItem: {
		paddingLeft: 0,
	},

	text: {
		marginTop: 0,
	},

	avatar: {
		minWidth: 'auto',
		marginRight: theme.spacing(6),
	},
}));
