import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Security, useOktaAuth } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { AuthenticatedUser } from 'typings/auth';
import { useSafeSetState } from 'hooks';
import analytics from '../services/analytics';
import { AuthenticationContext } from './AuthenticationContext';
import { postChatToken } from '../services';

export interface AuthenticationProviderValue {
	logOut: () => Promise<void>;
	isAuthenticated: boolean;
	currentUser: AuthenticatedUser | null;
	hasError: boolean;
	error?: Error;
}

const oktaConfig = {
	clientId: process.env.REACT_APP_CLIENT_ID,
	issuer: process.env.REACT_APP_ISSUER,
	redirectUri: `${window.location.origin}/login/callback`,
	scopes: ['openid', 'profile', 'email'],
	pkce: true,
};

const getAvatar = (seed: string) => `https://via.placeholder.com/80/d8d8d8/FFF?text=${seed}`;

const oktaAuthClient = new OktaAuth(oktaConfig);

const AuthenticationWithOkta: React.FC = (props) => {
	const { authState, oktaAuth } = useOktaAuth();

	const [user, setUser] = useSafeSetState<AuthenticatedUser | null>(null);
	const [error, setError] = useSafeSetState<Error | undefined>(undefined);

	useEffect(() => {
		if (authState.isAuthenticated) {
			(async () => {
				try {
					const {
						sub: id,
						name,
						given_name: firstName,
						family_name: lastName,
						tenant_id: tenantId,
						email,
					} = await oktaAuth.getUser();

					// Get the authenticated users' access token
					const apiToken = await oktaAuth.getAccessToken();

					// Get the token to connect to the get stream api
					const { token: chatToken } = await postChatToken({ accessToken: apiToken });

					const avatar = getAvatar(`${firstName?.charAt(0)}${lastName?.charAt(0)}`);

					analytics.identify(id, {
						email,
						name,
					});

					setUser({
						id,
						name,
						firstName,
						lastName,
						email,
						chatToken,
						apiToken,
						tenantId,
						image: avatar,
					});
				} catch (e) {
					setError(e);
					console.log(e);
				}
			})();
		}
	}, [authState, oktaAuth]);

	const logOut = useCallback(async () => {
		setUser(null);
		await oktaAuth.signOut();
	}, []);

	const isAuthenticated = !!user || false;

	const value = useMemo(() => {
		return {
			logOut,
			isAuthenticated,
			currentUser: user,
			hasError: !!error,
			error,
		};
	}, [user, isAuthenticated, error]);

	return (
		<AuthenticationContext.Provider value={value} {...props} />
	);
};

export const AuthenticationProvider: React.FC = (props) => {
	const history = useHistory();

	const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
		history.replace(toRelativeUrl(originalUri, window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri}>
			<AuthenticationWithOkta {...props} />
		</Security>
	);
};

export const useAuthentication: () => AuthenticationProviderValue = () => {
	const authentication = useContext(AuthenticationContext);

	if (authentication === null) {
		throw new Error(
			'authentication should be provided in AuthenticationProvider'
		);
	}

	return authentication;
};
