import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDocumentTitle } from 'hooks';
import { Card, Landscape } from 'typings/cms';
import { Insights } from 'components/Insights';
import { PageTitle } from 'components/PageTitle';
import { PageCard } from 'components/PageCard';
import { useChat, useChatChannels } from 'components/Chat';
import { stripHtml } from 'utils';
import { useAuthentication } from 'authentication';
import { Typography } from '@material-ui/core';
import { getLandscapeById } from 'services';
import { TENANT_MANIFOLD } from 'constants/tenants';
import { useStyles } from './styles';

export interface LandscapeRouteParams {
	landscapeId: string;
}

export const getLandscapeTitle = (landscape: Landscape, tenantId?: string): string => {
	return `${tenantId === TENANT_MANIFOLD ? `${landscape?.client?.name} :: ` : ''}${landscape?.title}`;
};

const truncateChannelPrefix = (id: string): string => id.substr(10);

export const LandscapePage: React.FC = observer(() => {
	const { setTitle } = useDocumentTitle();
	const { landscapeId } = useParams<LandscapeRouteParams>();
	const location = useLocation();
	const { currentUser } = useAuthentication();

	const accessToken = currentUser?.apiToken;

	const { isLoading, isError, data } = useQuery(
		['landscape', landscapeId],
		() => getLandscapeById({ landscapeId, accessToken }),
		{
			// The query will not execute until the accessToken exists
			enabled: !!accessToken,
			staleTime: 60 * 1000,
		}
	);

	const { streamChat, openChat } = useChat();

	useEffect(() => {
		(async () => {
			try {
				if (currentUser) {
					await streamChat.connectUser(currentUser);
				}
			} catch (e) {
				console.log(e);
			}
		})();
	}, [currentUser]);

	const classes = useStyles();

	// Document title effect
	useEffect(() => {
		if (data) {
			setTitle(data?.title);

			if (location.hash) {
				setTimeout(() => {
					const ele = document.getElementById(location.hash.replace('#', ''));
					ele?.scrollIntoView();
				}, 0);
			}
		}
	}, [data]);

	const channelIds = useMemo(() => {
		return ([
			// Append all insights ids
			...data?.insights.map(i => i.id) ?? [],
			// Append all card ids
			...data?.cards.map(c => c.id) ?? [],
		]);
	}, [data]);

	const {
		getChannel,
		channels,
	} = useChatChannels(channelIds, { prefix: truncateChannelPrefix(`${data?.client.id}_${landscapeId}`) });

	const insightsWithChannel = useMemo(() => {
		return data?.insights.map((insight: any) => ({
			...insight,
			channel: getChannel(insight.id),
		}));
	}, [data?.client, data?.insights, channels]);

	const cardsWithChannel = useMemo(() => {
		return data?.cards.map((card: Card) => ({
			...card,
			channel: getChannel(card.id),
		})) ?? [];
	}, [data?.client, data?.cards, channels]);

	const handleOnCommentClick = useCallback((handlerData: {title: string, id?: string}) => {
		const { title, id, ...rest } = handlerData;
		openChat({
			title: stripHtml(title, 40),
			id: truncateChannelPrefix(`${data?.client.id}_${landscapeId}_${id}`), ...rest,
		});
	}, [data]);

	return (
		<div className='landscape'>
			<Container maxWidth='lg' className={classes.container}>

				{isLoading && !isError && (
					<Typography>Loading...</Typography>
				)}

				{!isLoading && isError && (
					<Typography color='secondary'>
						Error fetching the landscape.
					</Typography>
				)}

				{data && !isLoading && !isError && (
					<React.Fragment>
						<Grid container spacing={8}>
							<Grid item xs={9}>
								<PageTitle
									title={getLandscapeTitle(data, currentUser?.tenantId)}
									subtitle={data?.description}
								/>{' '}
							</Grid>
						</Grid>

						<Grid container spacing={8}>
							<Grid item xs={12}>
								<Box display='flex' flex='1'>
									<Insights items={insightsWithChannel} onCommentClick={handleOnCommentClick} />
								</Box>
							</Grid>
						</Grid>

						<Grid container spacing={8}>
							{cardsWithChannel.map((card, idx) => {
								return (
									<Grid key={idx} item xs={12}>
										{
											card.isTitle ?
												<Grid container spacing={8}>
													<Grid item xs={9}>
														<PageTitle
															title={card.title}
															subtitle={stripHtml(card.subtitle)}
														/>{' '}
													</Grid>
												</Grid>
												:
												<PageCard
													id={card.id}
													type={card.type}
													title={card.title}
													subtitle={card.subtitle}
													description={card.description}
													records={card.data}
													metadata={card.metadata ?? {}}
													onCommentClick={handleOnCommentClick}
													channel={card.channel}
													toc={card.toc}
												/>
										}
									</Grid>
								);
							})}
						</Grid>
					</React.Fragment>
				)}
			</Container>
		</div>
	);
});
