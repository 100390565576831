import React, { useMemo } from 'react';
import { Row, SortByFn, useSortBy, useTable } from 'react-table';
import Icon from '../Icon/Icon';
import './Table.scss';
import { DataParser, DataRecord } from '../../typings/app';

export interface TableProps {
	data: DataRecord[];
	columnOrder: string[];
}

const columnSortTypeForObject = (object: any): SortByFn<any> => {
	if (object instanceof Date) {
		return (rowA: Row<Date>, rowB: Row<Date>, columnId, desc) => {
			if (rowA.original > rowB.original) {
				return desc ? 1 : -1;
			}

			return desc ? -1 : 1;
		};
	}

	if (React.isValidElement(object)) {
		return (rowA: Row<any>, rowB: Row<any>, columnId) => {
			if (
				rowA.original[columnId].props.children.toString() >
				rowB.original[columnId].props.children.toString()
			) {
				return 1;
			}

			return -1;
		};
	}

	return () => 0;
};

const columnSortType = (dataRow: Record<string, unknown>, key: string): string | SortByFn<any> => {
	switch (typeof dataRow[key]) {
		case 'number':
			return 'basic';
		case 'object':
			return columnSortTypeForObject(dataRow[key]);
		default:
			return 'alphanumeric';
	}
};

const columnExtractor = (data: DataRecord[], columnOrder: string[]) => {
	if (data[0] === undefined) {
		return [];
	}

	const columnSorter = (a: any, b: any): number => {
		return columnOrder.indexOf(a) - columnOrder.indexOf(b);
	};

	return Object.keys(data[0]).sort(columnSorter).map(key => {
		return {
			Header: key,
			accessor: key,
			sortType: columnSortType(data[0], key),
		};
	});
};

const Table: React.FC<TableProps> & DataParser = ({ data, columnOrder }) => {
	const tableColumns = useMemo(() => columnExtractor(data, columnOrder), [data]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns: tableColumns, data: data }, useSortBy);

	return (
		<table {...getTableProps()} className='Table'>
			<thead>
				{headerGroups.map(headerGroup => {
					const headerGroupProps = headerGroup.getHeaderGroupProps();
					return (
						<tr {...headerGroupProps} key={headerGroupProps.key}>
							{headerGroup.headers.map(column => {
								const columnHeaderProps = column.canSort ?
									column.getHeaderProps(column.getSortByToggleProps()) :
									column.getHeaderProps();

								return (
									<th {...columnHeaderProps} key={columnHeaderProps.key} title={column.id}>
										{column.render('Header')}
										{column.isSorted ? (
											<span className='Table__sort-indicator'>
												<Icon
													icon={column.isSortedDesc ? 'arrow-down' : 'arrow-up'}
												/>
											</span>
										) : null}
									</th>
								);
							})}
						</tr>
					);
				})}
			</thead>

			<tbody {...getTableBodyProps()}>
				{rows.map(row => {
					prepareRow(row);

					const rowProps = row.getRowProps();

					return (
						<tr {...rowProps} key={rowProps.key}>
							{row.cells.map(cell => {
								const cellProps = cell.getCellProps();

								return (
									<td
										{...cellProps}
										key={cellProps.key}
									>
										{cell.render('Cell')}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

Table.dataParser = (records: DataRecord[], metadata = {}) => {
	const componentData = records;
	const componentProps = { columnOrder: metadata.columnOrder, scrollable: true };

	return { componentData, componentProps };
};

export default Table;
