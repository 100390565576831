import { PostChatAccessTokenResponse } from '../../typings/api';
import { Landscape, Mentionable } from '../../typings/cms';
import { mapLandscape, mapMentionable } from './mapper';

interface postChatTokenType {
	accessToken?: string
}

const apiCall = (path: string, options: any) =>
	fetch(process.env.REACT_APP_API_URL + path, {
		'Content-Type': 'application/json',
		...options,
	}).then(response => {
		if (response.status === 204) {
			return Promise.resolve();
		}

		if (!response.ok) {
			throw response;
		}

		return response.json();
	});

export const postChatToken = ({ accessToken }: postChatTokenType): Promise<PostChatAccessTokenResponse> =>
	apiCall('/Chat/CreateAccessToken', {
		method: 'post',
		headers: {
			'Authorization': 'Bearer ' + accessToken,
		},
	});

interface getLandscapesType {
	accessToken?: string
}

export const getLandscapes = ({ accessToken }: getLandscapesType): Promise<Landscape[]> =>
	apiCall('/Content/GetLandscapes', {
		method: 'get',
		headers: {
			'Authorization': 'Bearer ' + accessToken,
		},
	})
		.then(data => data.landscapes.map(mapLandscape));

interface getLandscapeByIdType {
	landscapeId: string;
	accessToken?: string
}

export const getLandscapeById = ({ landscapeId, accessToken }: getLandscapeByIdType): Promise<Landscape> =>
	apiCall(`/Content/GetLandscapes?LandscapeId=${landscapeId}`, {
		method: 'get',
		headers: {
			'Authorization': 'Bearer ' + accessToken,
		},
	})
		.then(data => mapLandscape(data.landscapes[0]));

export const GetMentionableUsers = ({ accessToken }: Omit<getLandscapeByIdType, 'landscapeId'>): Promise<Mentionable[]> =>
	apiCall('/GetStream/GetMentionableUsers', {
		method: 'get',
		headers: {
			'Authorization': 'Bearer ' + accessToken,
		},
	})
		.then(data => data.users.map(mapMentionable));

