import { createModelSchema, object } from 'serializr';
import { AppStore } from './appStore';

export class RootStore {
	appStore: AppStore

	constructor() {
		this.appStore = new AppStore(this);
	}
}

// Create model schemas
createModelSchema(RootStore, {
	appStore: object(AppStore),
});

