import { withStyles } from '@material-ui/core/styles';
import MUITooltip from '@material-ui/core/Tooltip';

export interface TooltipProps {
	positionX: number;
	positionY: number;
}

export const TOOLTIP_WIDTH_PX = 200;
export const TOOLTIP_OFFSET_PX = 48;

export const Tooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 14,
	},
}))(MUITooltip);
