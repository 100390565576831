import React from 'react';
import ContentLoader from 'react-content-loader';

export const MessageListEmptySkeleton: React.FC<{ animate?: boolean }> = ({
	animate = false,
}) => {
	return (
		<ContentLoader
			animate={animate}
			speed={2}
			viewBox='0 0 500 550'
			backgroundColor='#f0f0f0'
			foregroundColor='#ecebeb'
			style={{ width: '100%' }}
		>
			<circle cx='40' cy='70' r='40' />
			<rect x='100' y='30' width='140' height='14' />
			<rect x='260' y='30' width='90' height='14' />
			<rect x='100' y='64' width='296' height='14' />
			<rect x='100' y='94' width='253.5' height='14' />
			<rect x='100' y='124' width='320' height='14' />

			<rect x='0' y='184' width='100%' height='1' />

			<circle cx='40' cy='254' r='40' />
			<rect x='100' y='214' width='140' height='14' />
			<rect x='260' y='214' width='90' height='14' />
			<rect x='100' y='244' width='360' height='14' />
			<rect x='100' y='274' width='299' height='14' />
			<rect x='100' y='304' width='249' height='14' />

			<rect x='0' y='364' width='100%' height='1' />

			<circle cx='40' cy='434' r='40' />
			<rect x='100' y='394' width='140' height='14' />
			<rect x='260' y='394' width='90' height='14' />
			<rect x='100' y='424' width='220' height='14' />
			<rect x='100' y='454' width='290' height='14' />
			<rect x='100' y='484' width='360' height='14' />

			<rect x='0' y='544' width='100%' height='1' />
		</ContentLoader>
	);
};
