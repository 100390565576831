import { makeStyles } from '@material-ui/core/styles';
import { COLOR_DARK_BLUE } from '../../theme';

export const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(0, 3),
		height: 205,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		textAlign: 'center',
		position: 'relative',
		whiteSpace: 'normal',
		color: '#1B2541',

	},
	avatar: {
		position: 'absolute',
		top: -theme.spacing(11),
		width: theme.spacing(19),
		height: theme.spacing(19),
	},
	button: {
		marginTop: theme.spacing(4),
		width: '82%',
	},
	modalActions: {
		padding: theme.spacing(3, 6),
	},
	title: {
		fontFamily: 'Ubuntu',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '14px',
		color: COLOR_DARK_BLUE,
		textTransform: 'uppercase',
		marginTop: theme.spacing(14),
	},
	subtitle: {
		fontSize: 14,
	},
}));
