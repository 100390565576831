import React, { useEffect, useMemo, useState } from 'react';
import { curveCardinal } from '@visx/curve';
import { Group } from '@visx/group';
import { PickD3Scale, scaleLinear, scaleTime, scaleOrdinal } from '@visx/scale';
import { Bar, LinePath } from '@visx/shape';
import { Grid } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { Text } from '@visx/text';
import { DataRecord, DataParser } from 'typings/app';
import { SpeciateMVPColors } from 'theme/colors';
import { Padding, defaultPadding, heightWithinPadding, widthWithinPadding } from '../../../theme/padding';
import { AXIS_LABEL_SIZE, AxisProps, defaultAxisProps } from '../AxisProps';
import './LineGraph.scss';
import { Metadata } from '../../../typings/cms';

export interface LineGraphData {
	[key: string]: LineGraphPoint[];
}

export interface LineGraphPoint {
	date: Date;
	value: number;
}

export interface LineGraphProps {
	/**
   * Props for the left and bottom axes
   */
	axisProps?: AxisProps,

	/**
   * Function for picking the line color for a given datapoint
   */
	colorFn?: (lineLabel: string) => string;

	/**
   * Date to render
   */
	data?: LineGraphData;

	/**
   * Number of points in the value space to leave between the highest and lowest
   * points of the graph
   */
	dataPadding?: number;

	/**
   * Outer height of the graph
   */
	height?: number;

	/**
   * Outer width of the graph
   */
	width?: number;

	/**
   * Padding around the graph
   */
	padding?: Padding;

	/**
   * Stroke dasharray for the lines
   */
	lineStrokeDashArray?: (lineLabel: string) => string;

	/**
   * Stroke width for the lines
   */
	lineStrokeWidth?: (lineLabel: string) => number;

	/**
   * Ordinal scale for determining the colors of the legend elements
   */
	legendColorScale: PickD3Scale<'ordinal', any, any>;

	/**
   * Width of the legend
   */
	legendWidth?: number;

	onClickElement?: (data: Record<string, any>) => void,
}

const LineGraph: React.FC<LineGraphProps> & DataParser = ({
	axisProps = defaultAxisProps,
	colorFn = () => 'black',
	data = {},
	dataPadding = 2,
	height = 500,
	legendColorScale,
	legendWidth = 150,
	lineStrokeDashArray = () => '',
	lineStrokeWidth = () => 2,
	width = 500,
	padding = defaultPadding,
	onClickElement = () => {},
}) => {
	const graphWidth =
    widthWithinPadding(width - legendWidth - axisProps?.leftAxisWidthPx - AXIS_LABEL_SIZE, padding);
	const graphWrapperWidth = width - legendWidth - AXIS_LABEL_SIZE;
	const graphHeight =
    heightWithinPadding(height - axisProps?.bottomAxisHeightPx - AXIS_LABEL_SIZE, padding);

	const date = (d: LineGraphPoint) => d.date.valueOf();
	const value = (d: LineGraphPoint) => d.value;

	const firstData = data[Object.keys(data)[0]];

	const allDataXScale = useMemo(() => {
		return scaleTime<number>({
			domain: [
				Math.min(...firstData.map(date)),
				Math.max(...firstData.map(date)),
			],
			range: [
				0,
				graphWidth,
			],
		});
	}, [
		date,
		firstData,
		padding,
		width,
	]);

	const allYData: number[] = useMemo(() => {
		return Object.keys(data).reduce((acc, dataKey) => {
			return acc.concat(data[dataKey].map(value));
		}, [] as number[]);
	}, [
		data,
	]);

	const yMin = useMemo(() => Math.min(...allYData), [allYData]);
	const yMax = useMemo(() => Math.max(...allYData), [allYData]);

	const allDataYScale = useMemo(() => {
		return scaleLinear<number>({
			domain: [
				yMin - dataPadding,
				yMax + dataPadding,
			],
			range: [
				graphHeight,
				0,
			],
			nice: true,
		});
	}, [
		dataPadding,
		graphHeight,
		padding,
		yMax,
		yMin,
	]);

	const lines = useMemo(
		() => {
			return Object.keys(data).map((lineLabel, lineIdx) => {
				const lineData = data[lineLabel];

				const xScale = scaleTime<number>({
					domain: [
						Math.min(...lineData.map(date)),
						Math.max(...lineData.map(date)),
					],
					range: [
						0,
						graphWidth,
					],
				});

				const yScale = scaleLinear<number>({
					domain: [
						yMin - dataPadding,
						yMax + dataPadding,
					],
					range: [
						graphHeight,
						0,
					],
					nice: true,
				});

				return {
					lineData,
					lineIdx,
					lineLabel,
					x: (d: LineGraphPoint) => xScale(date(d)) ?? 0,
					y: (d: LineGraphPoint) => yScale(value(d)) ?? 0,
				};
			});
		},
		[
			data,
			dataPadding,
			graphHeight,
			graphWidth,
			padding,
			yMax,
			yMin,
		]
	);

	const [disabledLines, setDisabledLines] = useState<string[]>([]);

	useEffect(() => {
		if (disabledLines) {
			onClickElement({ type: 'legend' });
		}
	}, [disabledLines]);

	return (
		<div
			className='LineGraph'
			style={{ height, width }}
		>
			<div
				className='LineGraph__legend'
				style={{ order: 1, width: legendWidth }}
			>
				<LegendOrdinal scale={legendColorScale}>
					{labels => (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{labels.map((label, i) => {
								const labelIsDisabled = disabledLines.indexOf(label.text) !== -1;

								return (
									<LegendItem
										className='LineGraph__legend-item'
										key={`LineGraph-legend-item-${i}`}
										onClick={() => {
											const newDisabledLines = !labelIsDisabled
												? disabledLines.slice().concat(label.text)
												: disabledLines.filter(line => line !== label.text);

											setDisabledLines(newDisabledLines);
										}}
									>
										<svg
											height={16}
											width={16}
										>
											<circle
												cx={8}
												cy={8}
												fill={labelIsDisabled ? 'transparent' : label.value}
												stroke={label.value}
												strokeWidth={2}
												r={6}
											/>
										</svg>
										<LegendLabel
											className='LineGraph__legend-label'
										>
											{label.text}
										</LegendLabel>
									</LegendItem>
								);
							})}
						</div>
					)}
				</LegendOrdinal>
			</div>

			<div className='LineGraph__graph'>
				<svg
					className='LineGraph__svg'
					height={height}
					width={graphWrapperWidth}
				>
					<rect
						className='LineGraph__background'
						x={0}
						y={0}
						width={graphWrapperWidth}
						height={height}
						fill='white'
					/>

					<Group
						className='LineGraph__content-wrapper'
						left={AXIS_LABEL_SIZE + axisProps?.leftAxisWidthPx + padding?.left}
						top={padding?.top}
						height={graphHeight}
						width={graphWidth - axisProps?.leftAxisWidthPx}
					>
						<Grid
							className='LineGraph__grid'
							xScale={allDataXScale}
							yScale={allDataYScale}
							height={graphHeight}
							numTicksColumns={axisProps?.bottomAxisNumTicks}
							numTicksRows={0}
							width={graphWidth}
							stroke='black'
							strokeOpacity={0.1}
						/>

						<AxisLeft
							axisClassName='LineGraph__AxisLeft'
							hideAxisLine
							scale={allDataYScale}
							tickFormat={axisProps?.leftAxisTickFormatter}
							tickLabelProps={axisProps?.leftAxisTickLabelProps}
							tickStroke={axisProps?.leftAxisTickStroke}
							label={axisProps?.leftAxisLabel}
							labelOffset={70}
							labelClassName={'LineGraph__AxisLeft-label'}
							stroke={'#e5e5e5'}
							strokeWidth={3}
						/>

						<AxisBottom
							axisClassName='LineGraph__AxisBottom'
							scale={allDataXScale}
							top={graphHeight}
							numTicks={axisProps?.bottomAxisNumTicks}
							tickFormat={axisProps?.bottomAxisTickFormatter}
							tickLabelProps={axisProps?.bottomAxisTickLabelProps}
							tickStroke={axisProps?.bottomAxisTickStroke}
							label={axisProps?.bottomAxisLabel}
							labelOffset={70}
							labelClassName={'LineGraph__AxisBottom-label'}
						/>

						<g className='LineGraph__lines'>
							{lines
								.filter(line => disabledLines.indexOf(line.lineLabel) === -1)
								.map(line => {
									return (
										<LinePath
											key={`line-graph-${line.lineLabel}`}
											data-name={line.lineLabel}
											className='LineGraph__line'
											curve={curveCardinal}
											data={line.lineData}
											stroke={colorFn(line.lineLabel)}
											strokeDasharray={lineStrokeDashArray(line.lineLabel)}
											strokeWidth={lineStrokeWidth(line.lineLabel)}
											x={line.x}
											y={line.y}
											width={graphWidth}
										/>
									);
								})}
						</g>

						<Bar
							className='LineGraph__line-hover-target'
							x={0}
							y={0}
							width={graphWidth}
							height={graphHeight}
							fill='transparent'
						/>
					</Group>
				</svg>
			</div>
		</div>
	);
};

LineGraph.dataParser = (records: DataRecord[], meta = {}) => {
	const metadata = meta as Metadata;
	const [recordXAxisKey, ...recordKeys] = Object.keys(records[0]);

	// Normalize highlights
	const keysToHighlight = metadata?.highlights ? metadata.highlights.map((v: string) => v.toLowerCase()) : [];

	const componentData: Record<string, any> = {};

	records.forEach((record: any) => {
		const date = new Date(record[recordXAxisKey]);

		recordKeys.forEach(key => {
			if (typeof componentData[key] === 'undefined') {
				componentData[key] = [];
			}

			componentData[key].push({ date: date, value: record[key] });
		});
	});

	const ordinalColorScale = scaleOrdinal({ domain: Object.keys(componentData), range: SpeciateMVPColors });
	const dateFormatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
	const componentProps: LineGraphProps = {
		axisProps: {
			...defaultAxisProps,
			leftAxisLabel: metadata?.yAxis?.label ?? '',
			bottomAxisLabel: metadata?.xAxis?.label ?? '',
			bottomAxisHeightPx: 80,
			leftAxisWidthPx: 60,
			// leftAxisTickFormatter: value => `${value}%`,
			bottomAxisTickFormatter: (value: Date): string => Intl.DateTimeFormat('en-US', dateFormatOptions).format(value),
			bottomAxisNumTicks: 10,
		},
		legendColorScale: ordinalColorScale,
		colorFn: (lineLabel) => ordinalColorScale(lineLabel),
		dataPadding: 0,
		lineStrokeDashArray: (lineLabel => {
			if (keysToHighlight.indexOf(lineLabel.toLowerCase()) == -1) {
				return '2 4';
			} else {
				return '';
			}
		}),
		lineStrokeWidth: (lineLabel => {
			if (keysToHighlight.indexOf(lineLabel.toLowerCase()) == -1) {
				return 2;
			} else {
				return 4;
			}
		}),
		padding: {
			bottom: 40,
			left: 40,
			right: 80,
			top: 40,
		},
	};

	return { componentData, componentProps };
};

export default LineGraph;
