import React from 'react';
import { IconName, icons } from 'eva-icons';
import './Icon.scss';

export interface IconProps {
	className?: string;
	fill?: string,
	height?: number;
	icon: IconName;
	width?: number;
}

const Icon: React.FC<IconProps> = ({
	className = '',
	fill = 'black',
	height = 24,
	icon,
	width = 24,
}) => {
	const matchedIcon = icons[icon];

	const computedClassName = `Icon Icon--icon-${icon} ${className}`;

	return (
		<span
			className={computedClassName}
			dangerouslySetInnerHTML={{
				__html: matchedIcon.toSvg({
					'aria-label': icon,
					fill,
					height,
					icon,
					width,
					role: 'img',
				}),
			}}
		/>
	);
};

export default Icon;
