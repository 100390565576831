import { makeAutoObservable } from 'mobx';
import { createModelSchema, map, primitive } from 'serializr';
import { RootStore } from './rootStore';

export const areDemoItemsEnabled = Boolean(process.env.REACT_APP_ENABLE_DEMO_ITEMS);

export class AppStore {
	readItems = new Map<string, boolean>();

	showDemoItems = false;
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
	}

	get showDemoableItems(): boolean {
		return this.showDemoItems && areDemoItemsEnabled;
	}

	toogleDemoableItems(): void {
		this.showDemoItems = !this.showDemoItems;
	}

	markItemAsRead(id: string): void {
		this.readItems.set(id, true);
	}

	isItemRead(id: string): boolean {
		return this.readItems.has(id);
	}
}

// Create model schemas
createModelSchema(AppStore, {
	showDemoItems: primitive(),
	readItems: map(primitive()),
});
