import mixpanel from 'mixpanel-browser';
import { getCookieConsentValue } from 'react-cookie-consent';
import { Analytics, Properties } from './types';

const RESERVED_PROPERTIES = [
	'email',
	'name',
	'first_name',
	'last_name',
	'$phone',
	'$created',
];

export class Mixpanel implements Analytics {
	constructor() {
		mixpanel.init(String(process.env.REACT_APP_MIXPANEL_TOKEN));
	}

	private isEnabled = () => {
		return getCookieConsentValue() === 'true';
	};

	track(event: string, properties?: Properties, timed = false): void {
		if (!this.isEnabled()) return;

		mixpanel.track(event, properties, () => {
			if (timed) {
				mixpanel.time_event(event); // Start time elapsed until next event track
			}
		});
	}

	identify(id: string, properties?: Properties): void {
		if (!this.isEnabled()) return;

		mixpanel.identify(id);

		if (properties) {
			const filteredProperties = Object.keys(properties).reduce(
				(carry, key) => {
					if (RESERVED_PROPERTIES.includes(key)) {
						return {
							...carry,
							[`$${key}`]: properties[key],
						};
					}
					return {
						...carry,
						[key]: properties[key],
					};
				},
				{}
			);

			mixpanel.people.set({
				USER_ID: id,
				...filteredProperties,
			});
		}
	}
}
