import React, { useCallback } from 'react';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import { formatDistance } from 'date-fns';
import Chart from 'assets/images/Chart.png';
import { ChannelObserver } from 'components/Chat';
import { Insight } from 'typings/cms';
import { useStyles } from './styles';
import { MessagesBadge } from '../MessagesBadge';

interface Props {
	item: Insight;
	onCommentClick?: (item: any) => void;
	messageCount: number;
}

export const InsightItem: React.FC<Props> = ({ item, onCommentClick }) => {
	const classes = useStyles();

	const handleOnCommentClick = useCallback(() => {
		if (onCommentClick) {
			onCommentClick({ title: item.text, id: item.id, type: 'insight' });
		}
	}, []);

	return (
		<ListItem alignItems='flex-start' className={classes.listItem}>
			<ListItemAvatar className={classes.avatar}>
				<img src={Chart} />
			</ListItemAvatar>
			<ListItemText
				className={classes.text}
				primary={
					<React.Fragment>
						<Typography
							component='span'
							variant='caption'
							color='textSecondary'
						>
							{formatDistance(
								item.created,
								new Date(),
								{ addSuffix: true }
							)}
						</Typography>
					</React.Fragment>
				}
				secondary={
					<React.Fragment>
						<Typography
							component='span'
							variant='body1'
							color='textSecondary'
							dangerouslySetInnerHTML={{ __html: item.text }}
						/>
					</React.Fragment>
				}
			/>
			<ChannelObserver channel={item.channel}>
				{({ messageCount }) => (
					<MessagesBadge messageCount={messageCount} onClick={handleOnCommentClick} />
				)}
			</ChannelObserver>
		</ListItem>
	);
};
