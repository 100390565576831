import React, { useCallback, useState } from 'react';
import { DataParser, DataRecord } from 'typings/app';
import { VegaLite } from 'react-vega';
import { Item, TooltipHandler } from 'vega-typings/types/runtime';
import { VisualizationSpec } from 'vega-embed';
import { Options as TooltipOptions } from 'vega-tooltip';
import { Renderers } from 'vega-typings/types/runtime/renderer';
import { createHash } from 'crypto';
import { useStyles } from './styles';
import { MediaMentionData } from '../../MediaMention/MediaMention';
import { Annotation } from '../../Annotation';
import MediaMentionModal from '../../MediaMention/MediaMentionModal';
import useAnnotation from '../../../hooks/useAnnotation';

export interface VegaLiteGraphProps {
	spec: VisualizationSpec;
	tooltip?: TooltipHandler | TooltipOptions | boolean;
	renderer?: Renderers;
	height?: number;
	width?: number;
}

export interface VegaLiteGraphPoint {
	Annotations?: Omit<MediaMentionData, 'term'>;
	ConfiguredValue: string;
}

const VegaLiteGraph: React.FC<VegaLiteGraphProps> & DataParser = (props) => {
	const classes = useStyles();

	const {
		currentEleRef,
		annotationIsOpen,
		hideAnnotation,
		annotationData,
		showAnnotation,
	} = useAnnotation<VegaLiteGraphPoint>();

	const [handledClick, setHandledClick] = useState<Record<string, boolean>>({});

	const tooltipHandler = useCallback<TooltipHandler>((handler: any, event: MouseEvent, item: Item, value: any) => {
		if (event == null || item == null || value == null) {
			return;
		}

		const target = event.target as HTMLElement;
		const data = item.datum as VegaLiteGraphPoint;
		data.ConfiguredValue = value;

		const handleClick = () => {
			showAnnotation({ referenceEle: target, annotationData: data });
		};

		const shasum = createHash('sha1');
		shasum.update(JSON.stringify(item.datum) + '-' + target.outerHTML);
		const hash = shasum.digest('hex');

		if (typeof handledClick[hash] === 'undefined') {
			event.target?.addEventListener('click', handleClick);
			handledClick[hash] = true;
			setHandledClick(handledClick);
		}
	}, []);

	const vegaProps = { spec: props.spec, tooltip: tooltipHandler, renderer: props.renderer, width: props.width };

	return <div className={classes.root}>
		<VegaLite {...vegaProps} />
		{annotationIsOpen && annotationData && (
			<Annotation isShowing onClickClose={hideAnnotation} targetEle={currentEleRef}>
				<MediaMentionModal
					numMentions={annotationData.Annotations?.mediaMentions?.length}
					insights={annotationData.Annotations?.insights ?? ''}
					mediaMentions={annotationData.Annotations?.mediaMentions ?? []}
					term={annotationData.ConfiguredValue}
				/>
			</Annotation>
		)}
	</div>;
};

VegaLiteGraph.dataParser = (records: DataRecord[], metadata = {}) => {
	// Verify and lock in schema
	if (typeof metadata.schema === 'undefined' && typeof metadata.$schema === 'undefined') {
		throw new Error('Missing schema');
	}

	const specification: VisualizationSpec = metadata;

	if (typeof metadata.$schema === 'undefined') {
		const schema = metadata.schema;
		delete metadata.schema;
		specification.$schema = schema;
	}

	const componentProps = {
		renderer: 'svg',
		spec: specification,
	};

	return { componentData: {}, componentProps };
};

export default VegaLiteGraph;
