import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { ParentSize } from '@visx/responsive';
import { DataRecord, PageCardComponentType } from 'typings/app';
import { Metadata } from 'typings/cms';
import { ChannelObserver } from 'components/Chat';
import { ChannelType } from 'services';
import analytics from 'services/analytics';
import { ANALYTICS_EVENTS } from 'constants/analytics-events';
import { convertCamelCaseToSnakeCase } from 'utils';
import { useOnScreen } from 'hooks';
import { useStore } from 'stores';
import Prism from 'prismjs';
import TwoByTwo from '../Graphs/TwoByTwo/TwoByTwo';
import TwoByTwoBipartite from '../Graphs/TwoByTwoBipartite/TwoByTwoBipartite';
import BarGraph from '../Graphs/BarGraph/BarGraph';
import LineGraph from '../Graphs/LineGraph/LineGraph';
import { useStyles } from './styles';
import HtmlBlob from '../Graphs/HtmlBlob/HtmlBlob';
import { MessagesBadge } from '../MessagesBadge';
import Table from '../Table/Table';
import VegaLiteGraph from '../Graphs/VegaLiteGraph/VegaLiteGraph';
import 'prismjs/themes/prism.css';

export interface PageCardProps {
	id: string;
	type: PageCardComponentType;
	records: DataRecord[];
	metadata: Metadata;
	height?: string;
	title: string;
	subtitle?: string;
	description?: string;
	data?: any[];
	channel?: ChannelType;
	onCommentClick?: (item: any) => void;
	toc: string;
}

export function componentType(componentType: PageCardComponentType): {error?: Error, Component?: React.ElementType} {
	const componentMap = {
		BarGraph,
		TwoByTwo,
		TwoByTwoBipartite,
		LineGraph,
		HtmlBlob,
		Table,
		VegaLiteGraph,
		// MultiBarGraph, // Not currently supporting multi bars
	};

	const Component = componentMap[componentType] ?? null;

	if (!Component) {
		return { error: new Error(`Unsupported lense type: ${componentType}`) };
	}

	return { Component };
}

const runDataParser = (
	component: any,
	data: DataRecord,
	metadata: Metadata
) => {
	let error;

	try {
		if (component !== undefined && typeof component.dataParser === 'function') {
			return component.dataParser(data, metadata);
		}
	} catch (e) {
		error = e;
		console.log(`Something went wrong parsing data for component ${component.name || 'Unknown'}`);
	}

	return { error, componentData: {}, componentProps: {} };
};

export const textToAnchor = (text: string, hash = false): string => {
	return (hash ? '#' : '') + text.toLowerCase().replaceAll(' ', '_');
};

const isFullSize = (type: string): boolean => ['HtmlBlob', 'VegaLiteGraph'].indexOf(type) === -1;
const isScrollable = (type: string): boolean => ['Table'].indexOf(type) !== -1;

function ErrorFallback() {
	return (
		<div role='alert'>
			<Typography color='secondary' variant='caption'>
				Error rendering lense data
			</Typography>
		</div>
	);
}

const errorHandler = (error: Error, info: {componentStack: string}) => {
	console.log(`Error caught in ${info?.componentStack ?? 'Unknown'}: `, error);
	// Do something with the error
	// E.g. log to an error logging client here
};

export const PageCard: React.FC<PageCardProps> = ({
	id,
	type,
	records,
	metadata,
	height = '75vh',
	title,
	subtitle = '',
	description = '',
	channel,
	onCommentClick,
	toc,
}) => {
	const classes = useStyles();

	const { appStore } = useStore();

	const { error: compError, Component } = useMemo(() => componentType(type), [type]);
	if (compError) {
		console.log(compError.message);
	}

	const cardType = useMemo(() => convertCamelCaseToSnakeCase(type), [type]);

	const { error, componentData, componentProps } = useMemo(
		() => runDataParser(Component, records, metadata),
		[type]
	);

	const handleOnCommentClick = useCallback(() => {
		if (onCommentClick) {
			onCommentClick({ title, id, type: `card_${cardType}` });
		}
	}, []);

	const handleOnClickElement = useCallback(data => {
		analytics.track(ANALYTICS_EVENTS.GRAPH_CLICK, {
			graph_type: cardType,
			graph_uuid: id,
			clicked_on: data?.type ?? 'unknown',
		});
	}, []);

	const [reference] = useOnScreen({
		timeout: 3000, // After 3 sec, then mark the card as read
		onReached: () => {
			const isRead = appStore.isItemRead(id);

			if (!isRead) {
				appStore.markItemAsRead(id);

				// Call analytics
				analytics.track(ANALYTICS_EVENTS.CARD_READ, {
					card_type: cardType,
					card_uuid: id,
				});
			}
		},
	});

	useEffect(() => Prism.highlightAll(), [Component]);

	if (error || compError || Component == undefined) {
		return (
			<Paper ref={reference} elevation={0} classes={{ root: classes.root }}>
				<ErrorFallback />
			</Paper>
		);
	}

	return (
		<Paper ref={reference} elevation={0} classes={{ root: classes.root }}>
			<ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
				<Typography
					className={classNames(classes.title, 'anchor-target')}
					variant='h5'
					id={textToAnchor(String(toc) || id)}
				>
					{title}
				</Typography>
				{subtitle && (
					<Typography
						variant='subtitle1'
						color='textSecondary'
						className={classes.subtitle}
						dangerouslySetInnerHTML={{ __html: subtitle }}
					/>
				)}
				<ChannelObserver channel={channel}>
					{({ messageCount }) => (
						<MessagesBadge
							messageCount={messageCount}
							onClick={handleOnCommentClick}
							className={classes.commentInfoCard}
						/>
					)}
				</ChannelObserver>
				<ParentSize>
					{parentSize => {
						return (
							<div
								style={{
									height: isFullSize(type) ? height : undefined,
									zIndex: 1,
									position: 'relative',
								}}
								className={
									isScrollable(type)
										? classes.scrollableCardWrapper
										: undefined
								}
							>
								<Component
									{...componentProps}
									id={id}
									data={componentData}
									height={parentSize.height}
									width={parentSize.width}
									onClickElement={handleOnClickElement}
								/>
							</div>
						);
					}}
				</ParentSize>
				{description && (
					<Typography
						variant='body1'
						color='textSecondary'
						className={classes.description}
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
			</ErrorBoundary>
		</Paper>
	);
};
