import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import {
	ChannelContext,
	MessageInputProps,
	UploadsPreview,
	useChannelContext,
	useMessageInput,
} from 'stream-chat-react';
import { useAuthentication } from 'authentication';
import { FileUploadButton } from 'react-file-utils';
import FileUploadIconImage from 'assets/images/attach-file-icon.png';
import { useAddFileStyles, useMessageInputStyles, useUploadPreviewStyles } from './styles';
import { ChatAutoComplete } from './ChatAutoComplete';

export const MessageInput: React.FC<MessageInputProps> = props => {
	const { currentUser } = useAuthentication();
	const classes = useMessageInputStyles();
	const messageInput = useMessageInput(props);
	const uploadClasses = useAddFileStyles();
	const uploadPreviewClasses = useUploadPreviewStyles();
	const channel = useContext(ChannelContext);
	const channelContext = useChannelContext();

	const user = currentUser;

	return (
		<div className='str-chat__input-flat'>
			<div className={uploadPreviewClasses.root}>
				{messageInput.isUploadEnabled && <UploadsPreview {...messageInput} />}
			</div>
			<div className='str-chat__input-flat-container'>
				{user && <img src={user.image} className={classes.avatar} />}
				{messageInput.isUploadEnabled && (
					<div className={uploadClasses.root} data-testid='fileinput'>
						<FileUploadButton
							accepts={channelContext.acceptedFiles}
							disabled={messageInput.maxFilesLeft === 0}
							handleFiles={messageInput.uploadNewFiles}
							multiple={channelContext.multipleUploads}
						>
							<img className={uploadClasses.uploadImage} src={FileUploadIconImage} />
						</FileUploadButton>
					</div>
				)}
				<ChatAutoComplete
					commands={messageInput.getCommands()}
					innerRef={messageInput.textareaRef}
					handleSubmit={messageInput.handleSubmit}
					onSelectItem={messageInput.onSelectItem}
					onChange={messageInput.handleChange}
					value={messageInput.text}
					rows={1}
					maxRows={5}
					placeholder={(channel.messages?.length || 0) > 0 ? 'Add a comment...' : 'Add the first comment ...'}
					onPaste={messageInput.onPaste}
					triggers={props.autocompleteTriggers}
					disabled={props.disabled}
					disableMentions={props.disableMentions}
					SuggestionList={props.SuggestionList}
					additionalTextareaProps={props.additionalTextareaProps}
					grow
				/>
				<Button
					variant='contained'
					color='secondary'
					onClick={messageInput.handleSubmit}
					className={classes.sendButton}
					size='small'
				>
					Post
				</Button>
			</div>
		</div>
	);
};
