import React from 'react';
import speciateLogo from '../../assets/images/speciateLogo.svg';
import speciateLogoFull from '../../assets/images/speciateLogoFull.svg';

interface SpeciateLogoProps {
	className?: string;
	fill?: string;
	height?: number;
	width?: number;
	full?: boolean;
}

const SpeciateLogo: React.FC<SpeciateLogoProps> = (
	{
		className = '',
		height = 44,
		width = 44,
		full = false,
	}) => {
	return (
		<img
			src={full ? speciateLogoFull : speciateLogo}
			className={`SpeciateLogo ${className}`}
			style={{
				height,
				width,
			}}
		/>
	);
};

export default SpeciateLogo;
