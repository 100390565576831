import React from 'react';
import { COLOR_BLACK } from 'theme/colors';
import './TwoByTwoLabelsToggle.scss';

export const TWO_BY_TWO_LABELS_TOGGLE_HEIGHT_PX = 50;

export interface TwoByTwoLabelsToggleProps {
	isActive?: boolean;
	onClick: () => void;
}

const TwoByTwoLabelsToggle: React.FC<TwoByTwoLabelsToggleProps> = ({
	isActive = false,
	onClick,
}) => {
	return (
		<button
			className='TwoByTwoLabelsToggle'
			onClick={onClick}
		>
			<svg
				height='16'
				width='16'
				fill={isActive ? COLOR_BLACK : '#cbcfda'}
				viewBox='0 0 100 100'
			>
				<path d='M55.863,5.079H6.941c-1.105,0-2,0.895-2,2v7.222c0,1.105,0.895,2,2,2h48.922c1.105,0,2-0.895,2-2V7.079  C57.863,5.975,56.967,5.079,55.863,5.079z' />
				<path d='M85.22,31.261H6.941c-1.105,0-2,0.895-2,2v7.222c0,1.105,0.895,2,2,2h78.28c1.105,0,2-0.895,2-2v-7.222  C87.22,32.156,86.325,31.261,85.22,31.261z' />
				<path d='M70.003,57.443H6.941c-1.105,0-2,0.895-2,2v7.222c0,1.105,0.895,2,2,2h63.063c1.105,0,2-0.895,2-2v-7.222  C72.003,58.338,71.108,57.443,70.003,57.443z' />
				<path d='M93.115,83.624H6.941c-1.105,0-2,0.895-2,2v7.222c0,1.105,0.895,2,2,2h86.175c1.105,0,2-0.895,2-2v-7.222  C95.115,84.52,94.22,83.624,93.115,83.624z' />
			</svg>
		</button>
	);
};

export default TwoByTwoLabelsToggle;
