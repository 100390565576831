import React from 'react';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { formatDistance } from 'date-fns';
import {
	Avatar,
	AvatarProps,
	Channel,
	ChannelHeader,
	Chat as StreamChat,
	LoadingErrorIndicatorProps,
	LoadingIndicatorProps,
	MessageList,
	MessageLivestream,
	MessageUIComponentProps,
	Thread,
	Window,
} from 'stream-chat-react';
import { ChannelType, StreamChatService } from '../../services';
import { useIndicatorStyles } from './styles';
import { MessageListEmptySkeleton } from './MessageListEmptySkeleton';
import { MessageInput } from './MessageInput';
import { EditMessageForm } from './EditMessageForm';

const MessageLoadingIndicator: React.FC<
LoadingIndicatorProps & { channel?: ChannelType }
> = ({ channel }) => {
	const classes = useIndicatorStyles();

	return (
		<Box className={classes.root}>
			<p className={classes.title}>
				{channel?.data?.name ?? 'Loading channel'}
			</p>
			<LinearProgress className={classes.progress} color='secondary' />
		</Box>
	);
};

const MessageErrorIndicator: React.FC<
LoadingErrorIndicatorProps & { channel?: ChannelType }
> = ({ error, channel }) => {
	const classes = useIndicatorStyles();

	return (
		<Box className={classes.root}>
			<p className={classes.error}>
				Oops! Something went wrong when loading the chat. Try again
				later.
			</p>
			<p className={classes.title}>{channel?.data?.name}</p>
			{process.env.NODE_ENV !== 'production' && error && (
				<p className={classes.error}>Error: {error.message}</p>
			)}
		</Box>
	);
};

const MessageListAvatar: React.FC<AvatarProps> = props => {
	return null;
};

const Message: React.FC<MessageUIComponentProps> = props => {
	return (
		<MessageLivestream
			{...props}
			EditMessageInput={EditMessageForm}
			formatDate={(date: Date) => {
				return formatDistance(date, new Date(), { addSuffix: true });
			}}
			actionsEnabled={false}
		/>
	);
};

export interface ChatProps {
	channel?: ChannelType;
	streamChat: StreamChatService;
}

export const Chat: React.FC<ChatProps> = props => {
	const { channel, streamChat } = props;

	return (
		<StreamChat client={streamChat.client} theme='livestream light'>
			{channel && (
				<Channel
					LoadingIndicator={props => (
						<MessageLoadingIndicator {...props} channel={channel} />
					)}
					LoadingErrorIndicator={props => (
						<MessageErrorIndicator {...props} channel={channel} />
					)}
					channel={channel}
					EmojiPicker={() => <i></i>}
				>
					<Window>
						<ChannelHeader live={false} />
						<MessageList
							messageActions={['edit']}
							Message={Message}
							Avatar={MessageListAvatar}
							EmptyStateIndicator={() => (
								<MessageListEmptySkeleton />
							)}
						/>
						<MessageInput />
					</Window>
					<Thread />
				</Channel>
			)}
		</StreamChat>
	);
};
