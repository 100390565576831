import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import { useStyles } from './styles';

export const CookieConsent: React.FC = () => {
	const styles = useStyles();

	return (
		<ReactCookieConsent
			disableStyles
			enableDeclineButton
			containerClasses={styles.container}
			contentClasses={styles.content}
			buttonClasses={styles.acceptButton}
			declineButtonClasses={styles.declineButton}
			buttonText='Allow cookies'
			declineButtonText='Decline'
		>
			This website uses cookies to ensure you get the best experience on
			our website.{' '}
			<a
				href='https://www.cookiesandyou.com/'
				rel='noreferrer'
				target='_blank'
			>
				Learn more
			</a>
		</ReactCookieConsent>
	);
};
