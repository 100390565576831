import { MediaMention } from '../components/MediaMention/MediaMention';
import { DataRecord } from '../typings/app';

export function buildMediaMentions(record: DataRecord): MediaMention[] {
	const mentions: any = {};

	Object.keys(record).forEach(key => {
		if (record[key] === '') {
			return;
		}

		const { prefix, base } = extractMentionPrefix(key.toLowerCase());

		if (base === undefined) {
			return;
		}

		if (mentions[prefix] === undefined) {
			mentions[prefix] = {};
		}

		mentions[prefix][base] = record[key].trim();
	});

	return Object.values(mentions);
}

function extractMentionPrefix(value: string): Record<string, string> {
	const pubNameSuffix = 'publication name';
	const quoteSuffix = 'quote';
	const urlSuffix = 'url';

	const column2Attribute: Record<string, string> = {};
	column2Attribute[pubNameSuffix] = 'publisherName';
	column2Attribute[quoteSuffix] = 'content';
	column2Attribute[urlSuffix] = 'url';

	let base = '';

	switch (true) {
		case value.endsWith(pubNameSuffix):
			base = pubNameSuffix;
			break;

		case value.endsWith(quoteSuffix):
			base = quoteSuffix;
			break;

		case value.endsWith(urlSuffix):
			base = urlSuffix;
			break;
	}

	return { prefix: value.replace(base, '').trim(), base: column2Attribute[base] };
}
