import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { PageTitle } from 'components/PageTitle';
import { useStore, areDemoItemsEnabled } from 'stores';
import { Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useStyles } from './styles';

export const Settings: React.FC = observer(() => {
	const classes = useStyles();
	const { appStore } = useStore();

	const handleChange = useCallback(() => {
		appStore.toogleDemoableItems();
	}, []);

	if (!areDemoItemsEnabled) {
		return <Redirect to='/' />;
	}

	return (
		<Container maxWidth='lg' className={classes.container}>
			<Grid container spacing={8}>
				<Grid item xs={12}>
					<PageTitle title='Settings' />
				</Grid>
			</Grid>
			<Paper elevation={2}>
				<Card>
					<CardContent>
						<Typography>Toggle Visual Demo Items</Typography>
						<Switch
							checked={appStore.showDemoableItems}
							onChange={handleChange}
							color='secondary'
						/>
					</CardContent>
				</Card>
			</Paper>
		</Container>
	);
});
