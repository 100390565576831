export interface Padding {
	bottom: number;
	left: number;
	right: number;
	top: number;
}

export const defaultPadding: Padding = {
	top: 20,
	right: 20,
	bottom: 20,
	left: 20,
};

function widthWithinPadding(width: number, padding: Padding): number {
	return width - padding.left - padding.right;
}

function heightWithinPadding(height: number, padding: Padding): number {
	return height - padding.top - padding.bottom;
}

export {
	heightWithinPadding,
	widthWithinPadding,
};
