import React from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './styles';
import { InsightItem } from './InsightItem';

export const InsightsAnchor = 'key_insights';

interface Props {
	items?: any[];
	onCommentClick?: (item: any) => void;
}

export const Insights: React.FC<Props> = ({ items, onCommentClick }) => {
	const classes = useStyles();

	return (
		<Paper elevation={0} classes={{ root: classes.root }}>
			<Typography className={classNames(classes.title, 'anchor-target')} variant='h5' id={InsightsAnchor}>Key Insights</Typography>
			{items?.length === 0 ? (
				<Typography variant='subtitle1' color='textSecondary'>No key insights at the moment</Typography>
			) : <List>
				{items?.map((item, index) => (
					<InsightItem
						key={index}
						item={item}
						onCommentClick={onCommentClick}
						messageCount={item.messageCount}
					/>
				))}
			</List>}
		</Paper>
	);
};

