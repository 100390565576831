import { useRef, useEffect, useState } from 'react';

export function useSafeSetState<T = unknown | undefined>(initialState: T): [T, (value: T) => false | void] {

	const [state, setState] = useState(initialState);

	const mountedRef = useRef(false);

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const safeSetState = (value: T) => mountedRef.current && setState(value);

	return [state, safeSetState];
}
