export const COLOR_BLACK = '#0a142b';
export const COLOR_DARK_BLUE = '#000d42';
export const COLOR_DARK_PINK = '#B80A57';
export const COLOR_LIGHT_PINK = '#e90a6d';
export const COLOR_ORANGE = '#f09d51';
export const COLOR_YELLOW = '#fabc2a';
export const COLOR_GREEN = '#44cf6c';
export const COLOR_LIGHT_BLUE = '#247BDF';
export const COLOR_TEAL = '#046e8f';
export const COLOR_MIDDLE_BLUE = '#1049ba';
export const COLOR_PURPLE = '#6564db';
export const COLOR_LIGHT_GREY = '#e5e5e5';

export const SpeciateMVPColors = [
	'#2D99FF',
	'#E12FAC',
	'#FFE700',
	'#2CD9C5',
	'#E12F54',
	'#FF6C40',
	'#826AF9',
	'#6AF993',
	'#FF2D2D',
	'#2CACD9',
	'#00FF03',
	'#295DCD',
	'#D1690E',
	'#9B142F',
	'#22B587',
	'#C040BB',
	'#FFB600',
	'#1184FF',
	'#98D61B',
	'#D87320',
	'#00FFA0',
	'#F4D509',
	'#FF6621',
	'#420C5C',
];
