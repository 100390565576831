import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { LoginCallback } from '@okta/okta-react';
import { CookieConsent } from 'components/CookieConsent/CookieConsent';
import { SecureRoute } from 'authentication';
import { MainLayout } from './components/MainLayout';
import { Home } from './pages/Home';
import { LandscapePage } from './pages/Landscape';
import { Settings } from './pages/Settings';
import LandscapeSidebar from './components/Sidebar/LandscapeSidebar';

const useStyles = makeStyles({
	root: {
		display: 'flex',
	},
});

const App: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Switch>
				<Route path='/login/callback' exact>
					<LoginCallback />
				</Route>
				<SecureRoute path='/' exact>
					<MainLayout>
						<Home />
					</MainLayout>
				</SecureRoute>
				<SecureRoute path='/landscape/:landscapeId' exact>
					<MainLayout sidebar={<LandscapeSidebar />}>
						<LandscapePage />
					</MainLayout>
				</SecureRoute>
				<SecureRoute path='/settings' exact>
					<MainLayout>
						<Settings />
					</MainLayout>
				</SecureRoute>
				<Route path='*'>
					<Redirect to='/' />
				</Route>
			</Switch>
			<CookieConsent />
		</div>
	);
};
export default App;
