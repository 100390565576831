import React from 'react';
import { observer } from 'mobx-react-lite';
import MUIDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useStyles } from './styles';
import JumpToMenu from '../../JumpToMenu';
import { AnalystCard } from '../../AnalystCard';
import { LandscapeRouteParams } from '../../../pages/Landscape';
import { getLandscapeById } from '../../../services';
import { useAuthentication } from '../../../authentication';

export const LandscapeSidebar: React.FC = observer(() => {
	const classes = useStyles();
	const { landscapeId } = useParams<LandscapeRouteParams>();
	const { currentUser } = useAuthentication();

	const accessToken = currentUser?.apiToken;

	const { isLoading, isError, data } = useQuery(
		['landscape', landscapeId],
		() => getLandscapeById({ landscapeId, accessToken }),
		{
			// The query will not execute until the accessToken exists
			enabled: !!accessToken,
			staleTime: 60 * 1000,
		}
	);

	return (
		<MUIDrawer variant='permanent' classes={{ paper: classes.drawerPaper }} open={false}>
			<List className={classes.list}>
				<JumpToMenu isMenu isLoading={isLoading} isError={isError} cards={data?.cards} />
				<AnalystCard />
			</List>
		</MUIDrawer>
	);
});
