import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	commentInfo: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 10,
		cursor: 'pointer',
		width: 25,

		'& img': {
			width: 25,
			minWidth: 25,
		},

		'& p': {
			position: 'absolute',
			top: '0px',
			width: '25px',
			fontWeight: 'bold',
			fontSize: '13px',
			lineHeight: '20px',
			zIndex: 1199,
			textAlign: 'center',
			color: theme.palette.common.white,
		},
	},
}));
