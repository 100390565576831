import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

interface Props {
	/**
	 * Page Title
	 */
	title: string;
	/**
	 * Page subtitle / Summary
	 */
	subtitle?: string;
}

export const PageTitle: React.FC<Props> = props => {
	const classes = useStyles();

	const { title, subtitle } = props;

	return (
		<div className={classes.root}>
			<Typography gutterBottom variant='h4'>
				{title}
			</Typography>
			{subtitle && (
				<Typography variant='body1' color='textSecondary'>
					{subtitle}
				</Typography>
			)}
		</div>
	);
};
