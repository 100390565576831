import { Channel, StreamChat } from 'stream-chat';
import { AuthenticatedUser } from 'typings/auth';

const streamKey = String(process.env.REACT_APP_GET_STREAM_KEY);

export type StreamChatService = {
	client: StreamChat;
	createChannel: (id: string, name: string, custom?: {[key:string]: string}) => Channel;
	queryChannel: (id: string) => Promise<ChannelType | undefined>;
	queryChannels: (channelIds: string[]) => Promise<ChannelType[]>;
	connectUser: (user: AuthenticatedUser) => Promise<any>;
	disconnect: () => Promise<any>;
	getCurrentUser: () => AuthenticatedUser | undefined
};

export type ChannelType = Channel;

export const configureStreamChat = (): StreamChatService => {
	let currentUser: AuthenticatedUser | undefined;
	const client = StreamChat.getInstance(streamKey);

	const connectUser = (user: AuthenticatedUser) => {
		const { chatToken, id, name, image } = user;

		currentUser = user;

		// Avoid reconnect
		if (id === client.userID && client.setUserPromise) {
			return Promise.resolve(client.setUserPromise);
		}

		return client.connectUser({ id, name, image }, chatToken);
	};

	const disconnect = () => {
		currentUser = undefined;
		return client.disconnect();
	};

	const createChannel = (id: string, name: string, data?: {[key:string]: any}): Channel => {
		return client.channel('messaging', id, {
			// add as many custom fields as you'd like
			name: name,
			data: {
				...data,
			},
		});
	};

	const queryChannels = async (channelIds: string[]) => {
		const filter = {
			type: 'messaging',
			id: { $in: channelIds },
		};

		return await client.queryChannels(filter, [], {});
	};

	const queryChannel = async (channelId: string) => {
		const filter = {
			type: 'messaging',
			id: channelId,
		};

		const channels = await client.queryChannels(filter, [], {});

		return channels?.[0] ?? null;
	};

	const getCurrentUser = () => currentUser;

	return {
		client,
		createChannel,
		queryChannel,
		queryChannels,
		connectUser,
		disconnect,
		getCurrentUser,
	};
};
