import React from 'react';
import Popover from '@material-ui/core/Popover';
import { WithBoundingRectsProps } from '@visx/bounds';
import './Annotation.css';

export interface AnnotationProps extends WithBoundingRectsProps {
	isShowing?: boolean;
	targetEle?: HTMLElement;
	onClickClose?: (e: MouseEvent) => void;
}

export const Annotation: React.FC<AnnotationProps> = ({
	children,
	targetEle,
	onClickClose,
	isShowing = false,
}) => {

	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	React.useEffect(() => {
		if (isShowing && targetEle) {
			setAnchorEl(targetEle);
		}
	}, [isShowing]);

	const handleClose = (e: MouseEvent) => {
		setAnchorEl(null);
		if (onClickClose) {
			onClickClose(e);
		}
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'left',
			}}
		>
			<div className='AnnotationContent'>{children}</div>
		</Popover>
	);
};
