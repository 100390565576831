import React, { useEffect, useMemo, useState } from 'react';
import './TwoByTwoBipartite.scss';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Text } from '@visx/text';
import { PickD3Scale, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Grid } from '@visx/grid';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { DataParser, DataRecord } from 'typings/app';
import { Metadata } from 'typings/cms';
import { areRecordsValid, buildDataPoints, getGraphDomain } from 'utils/graphs';
import { COLOR_DARK_BLUE, COLOR_LIGHT_BLUE } from 'theme/colors';
import { defaultPadding, heightWithinPadding, Padding, widthWithinPadding } from 'theme/padding';
import useAnnotation from 'hooks/useAnnotation';
import { AXIS_LABEL_SIZE, AxisProps, defaultAxisProps } from '../AxisProps';
import {
	TwoByTwoCircleMouseEvent,
	TwoByTwoColorFunction,
	TwoByTwoCrossbarPositionFunction,
	TwoByTwoDotRadiusFunction,
	TwoByTwoPoint,
} from '../TwoByTwo/TwoByTwo';
import { Annotation } from '../../Annotation';
import MediaMentionModal from '../../MediaMention/MediaMentionModal';
import { Tooltip } from '../../Tooltip';
import TwoByTwoLabelsToggle, { TWO_BY_TWO_LABELS_TOGGLE_HEIGHT_PX } from '../TwoByTwoLabelsToggle/TwoByTwoLabelsToggle';
import { buildMediaMentions } from '../../../utils';

interface point {
	x: number;
	y: number;
}

const computeAngleBetweenPointsInRadians = (p1: point, p2: point): number => {
	return Math.atan2(
		p2.y - p1.y,
		p2.x - p1.x
	);
};

const pointAtDistanceFromPoint = (p: point, angleInRadians: number, distance: number): point => {
	return {
		x: p.x + (distance * (Math.cos(angleInRadians))),
		y: p.y + (distance * (Math.sin(angleInRadians))),
	};
};

export interface TwoByTwoBipartitePoints {
	a: TwoByTwoPoint,
	b: TwoByTwoPoint,
}

export interface TwoByTwoBipartiteProps {
	/**
   * Props for the left and bottom axes
   */
	axisProps?: AxisProps,

	/**
   * Function for computing the fill color of a dot in the "A" group
   */
	colorFnA?: TwoByTwoColorFunction;

	/**
   * Function for computing the fill color of a dot in the "B" group
   */
	colorFnB?: TwoByTwoColorFunction;

	horizontalCrossBarPosFn?: TwoByTwoCrossbarPositionFunction;

	verticalCrossBarPosFn?: TwoByTwoCrossbarPositionFunction;

	/**
   * Data to render
   */
	data?: TwoByTwoBipartitePoints[];

	/**
   * Number of data points to pad the X axis data by
   */
	dataPaddingX?: number;

	/**
   * Number of data points to pad the Y axis data by
   */
	dataPaddingY?: number;

	/**
   * Function for computing the radius of a dot
   */
	dotRadiusFn?: TwoByTwoDotRadiusFunction;

	/**
   * Outer height of the graph
   */
	height?: number;

	/**
   * Outer width of the graph
   */
	width?: number;

	/**
   * Padding around the graph
   */
	padding?: Padding;

	/**
   * Ordinal scale for determining the colors of the legend elements
   */
	legendColorScale?: PickD3Scale<'ordinal', any, any>;

	/**
   * Height of the legend
   */
	legendHeight?: number;

	onClickElement?: (data: Record<string, any>) => void,

}

const GRAPH_PADDING = 8;

const TwoByTwoBipartite: React.FC<TwoByTwoBipartiteProps> & DataParser = ({
	axisProps = defaultAxisProps,
	colorFnA = () => COLOR_LIGHT_BLUE,
	colorFnB = () => COLOR_DARK_BLUE,
	data = [],
	dotRadiusFn = () => 8,
	height = 500,
	legendColorScale = scaleOrdinal({
		domain: ['a', 'b'],
		range: [COLOR_LIGHT_BLUE, COLOR_DARK_BLUE],
	}),
	legendHeight = 36,
	padding = defaultPadding,
	width = 500,
	onClickElement = () => {},
}) => {
	const graphWidth = widthWithinPadding(width - axisProps?.leftAxisWidthPx - AXIS_LABEL_SIZE, padding);
	const graphWrapperWidth = width - AXIS_LABEL_SIZE;
	const graphHeight = heightWithinPadding(
		height - axisProps?.bottomAxisHeightPx - AXIS_LABEL_SIZE -
		TWO_BY_TWO_LABELS_TOGGLE_HEIGHT_PX - GRAPH_PADDING, padding
	);

	const x = (d: TwoByTwoPoint) => d.x;
	const y = (d: TwoByTwoPoint) => d.y;
	const label = (d: TwoByTwoPoint) => d.label;

	const allXData = useMemo(() => {
		return data?.reduce((acc, points) => {
			return acc.concat(...[points.a.x, points.b.x]);
		}, [] as number[]);
	}, [
		data,
	]);

	const allYData = useMemo(() => {
		return data?.reduce((acc, points) => {
			return acc.concat(...[points.a.y, points.b.y]);
		}, [] as number[]);
	}, [
		data,
	]);

	const xScale = useMemo(() => {
		return scaleLinear({
			range: [
				0,
				graphWidth,
			],
			domain: getGraphDomain(allXData, 0.10),
		});
	}, [
		allXData,
		graphWidth,
	]);

	const yScale = useMemo(() => {
		return scaleLinear({
			range: [
				graphHeight,
				0,
			],
			domain: getGraphDomain(allYData),
		});
	}, [
		allYData,
		graphHeight,
	]);

	const xPoint = (d: TwoByTwoPoint) => xScale(x(d));
	const yPoint = (d: TwoByTwoPoint) => yScale(y(d));

	const {
		currentEleRef,
		annotationIsOpen,
		showAnnotation,
		hideAnnotation,
		annotationData,
	} = useAnnotation<TwoByTwoPoint>();

	useEffect(() => {
		if (annotationIsOpen) {
			onClickElement({ type: 'point', data: annotationData });
		}
	}, [annotationIsOpen]);

	const [disabledGroups, setDisabledGroups] = useState<string[]>([]);

	const drawLineBetweenDots = (
		d: TwoByTwoBipartitePoints,
		distanceOffset: number
	): JSX.Element => {
		const angleInRadians = computeAngleBetweenPointsInRadians(
			{ x: xPoint(d.a), y: yPoint(d.a) },
			{ x: xPoint(d.b), y: yPoint(d.b) }
		);

		const newDestPoint = pointAtDistanceFromPoint(
			{ x: xPoint(d.b), y: yPoint(d.b) },
			angleInRadians,
			distanceOffset
		);

		return (
			<line
				markerEnd='url(#arrowhead)'
				x1={xPoint(d.a)}
				x2={newDestPoint.x}
				y1={yPoint(d.a)}
				y2={newDestPoint.y}
				stroke='rgba(0,0,0,0.2)'
				strokeWidth={2}
			/>
		);
	};

	const [showAllLabels, setShowAllLabels] = useState<boolean>(false);

	useEffect(() => {
		if (showAllLabels) {
			onClickElement({ type: 'show_labels' });
		}
	}, [showAllLabels]);

	return (
		<div
			className='TwoByTwoBipartite'
			style={{
				height,
				width,
			}}
		>
			<div
				className='TwoByTwoLabelsToggle__wrapper'
				style={{
					marginBottom: -legendHeight,
					paddingRight: padding?.right,
					paddingTop: padding?.top,
					width,
				}}
			>
				<TwoByTwoLabelsToggle
					isActive={showAllLabels}
					onClick={() => setShowAllLabels(!showAllLabels)}
				/>
			</div>

			<div
				className='TwoByTwoBipartite__legend'
				style={{
					height: legendHeight,
					width: graphWrapperWidth,
				}}
			>
				<LegendOrdinal scale={legendColorScale}>
					{labels => (
						<div className='TwoByTwoBipartite__legend-contents'>
							{labels.map((label, i) => {
								const labelIsDisabled = disabledGroups.indexOf(label.text) !== -1;

								return (
									<LegendItem
										className='TwoByTwoBipartite__legend-item'
										key={`TwoByTwoBipartite-legend-item-${i}`}
										onClick={() => {
											const newDisabledLines = !labelIsDisabled
												? disabledGroups.slice().concat(label.text)
												: disabledGroups.filter(line => line !== label.text);

											setDisabledGroups(newDisabledLines);
										}}
									>
										<svg
											height={16}
											width={16}
										>
											<circle
												cx={8}
												cy={8}
												fill={labelIsDisabled ? 'transparent' : label.value}
												stroke={label.value}
												strokeWidth={2}
												r={6}
											/>
										</svg>
										<LegendLabel
											className='TwoByTwoBipartite__legend-label'
										>
											{label.text}
										</LegendLabel>
									</LegendItem>
								);
							})}
						</div>
					)}
				</LegendOrdinal>
			</div>

			<div
				className='TwoByTwoBipartite__graph'
				style={{
					height: height,
				}}
			>
				<svg
					className='TwoByTwoBipartite__svg'
					height={height - (legendHeight * 2)}
					width={width}
				>
					<defs>
						<marker
							id='arrowhead'
							fill='rgba(0,0,0,0.2)'
							markerWidth='8'
							markerHeight='5'
							refX='0'
							refY='2.5'
							orient='auto'>
							<polygon points='0 0, 8 2.5, 0 5' />
						</marker>
					</defs>

					<rect
						className='TwoByTwoBipartite__background'
						x={0}
						y={0}
						width={width}
						height={height}
						fill='white'
					/>

					<Group
						className='TwoByTwoBipartite__content-wrapper'
						left={AXIS_LABEL_SIZE + axisProps?.leftAxisWidthPx + padding?.left}
						top={GRAPH_PADDING}
						height={graphHeight}
						width={graphWidth - axisProps?.leftAxisWidthPx}
					>
						<Grid
							className='TwoByTwoBipartite__grid'
							xScale={xScale}
							yScale={yScale}
							width={graphWidth}
							height={graphHeight}
							stroke='#e5e5e5'
							strokeDasharray={'6 6'}
						/>

						<AxisLeft
							axisClassName='TwoByTwoBipartite__AxisLeft'
							scale={yScale}
							tickFormat={axisProps?.leftAxisTickFormatter}
							label={axisProps?.leftAxisLabel}
							labelOffset={70}
							labelClassName={'TwoByTwoBipartite__AxisLeft-label'}
							stroke={'#e5e5e5'}
							strokeWidth={3}
						/>

						<AxisBottom
							axisClassName='TwoByTwoBipartite__AxisBottom'
							numTicks={axisProps?.bottomAxisNumTicks}
							scale={xScale}
							top={graphHeight}
							tickFormat={axisProps?.bottomAxisTickFormatter}
							label={axisProps?.bottomAxisLabel}
							labelOffset={30}
							labelClassName={'TwoByTwoBipartite__AxisBottom-label'}
							stroke={'#e5e5e5'}
							strokeWidth={3}
						/>

						<Group
							className='TwoByTwoBipartite__content'
							height={graphHeight}
							width={graphWidth}
						>
							{data.map((d, i) => {
								return (
									<Group
										key={`TwoByTwoBipartite__dot-group-${i}`}
									>
										{disabledGroups.length === 0 && (
											drawLineBetweenDots(d, -(dotRadiusFn(d.b) * 3))
										)}

										{(d.a.group === undefined || disabledGroups.indexOf(d.a.group) === -1) &&
										showAllLabels && (
											<Text
												fontSize={14}
												fontWeight={700}
												textAnchor='middle'
												x={xPoint(d.a)}
												y={yPoint(d.a) - 24}
											>{label(d.a)}</Text>
										)}

										{(d.a.group === undefined || disabledGroups.indexOf(d.a.group) === -1) && (
											<Tooltip
												title={
													<React.Fragment>
														<div>
															<strong>{label(d.a)}</strong>
														</div>
													</React.Fragment>
												}
												placement='top'
												disableHoverListener={showAllLabels}
												disableFocusListener
											>
												<circle
													className='TwoByTwoBipartite__dot'
													fill={colorFnA(d.a)}
													opacity={1}
													cx={xPoint(d.a)}
													cy={yPoint(d.a)}
													r={dotRadiusFn(d.a)}
													onClick={(e: TwoByTwoCircleMouseEvent) => {
														if (!d.a.mediaMentionData) {
															return;
														}
														showAnnotation({
															referenceEle: e.target,
															annotationData: d.a,
														});
													}}
												/>
											</Tooltip>

										)}

										{(d.b.group === undefined || disabledGroups.indexOf(d.b.group) === -1) &&
										showAllLabels && (
											<Text
												fontSize={14}
												fontWeight={700}
												textAnchor='middle'
												x={xPoint(d.b)}
												y={yPoint(d.b) - 24}
											>{label(d.b)}</Text>
										)}

										{(d.b.group === undefined || disabledGroups.indexOf(d.b.group) === -1) && (
											<Tooltip
												title={
													<React.Fragment>
														<div>
															<strong>{label(d.b)}</strong>
														</div>
													</React.Fragment>
												}
												placement='top'
												disableHoverListener={showAllLabels}
												disableFocusListener
											>
												<circle
													className='TwoByTwoBipartite__dot'
													fill={colorFnB(d.b)}
													opacity={1}
													cx={xPoint(d.b)}
													cy={yPoint(d.b)}
													r={dotRadiusFn(d.b)}
													onClick={(e: TwoByTwoCircleMouseEvent) => {
														if (!d.b.mediaMentionData) {
															return;
														}

														showAnnotation({
															referenceEle: e.target,
															annotationData: d.b,
														});
													}}
												/>
											</Tooltip>
										)}
									</Group>
								);
							})}
						</Group>
					</Group>
				</svg>
				{annotationIsOpen && annotationData && annotationData.mediaMentionData && (
					<Annotation
						isShowing
						onClickClose={() => hideAnnotation()}
						targetEle={currentEleRef}
					>
						<MediaMentionModal
							numMentions={annotationData.mediaMentionData.numMentions}
							insights={annotationData.mediaMentionData.insights}
							mediaMentions={annotationData.mediaMentionData.mediaMentions}
							term={annotationData.mediaMentionData.term}
						>
							<div>
								<em>{axisProps?.bottomAxisLabel}</em>: {annotationData.x}
							</div>
							<div>
								<em>{axisProps?.leftAxisLabel}</em>: {annotationData.y}
							</div>
						</MediaMentionModal>
					</Annotation>
				)}
			</div>
		</div>
	);
};

TwoByTwoBipartite.dataParser = (records: DataRecord[], metadata: Metadata) => {
	const { label, xAxis, yAxis } = metadata;

	if (!yAxis.columns || yAxis?.columns.length !== 2) {
		return {};
	}

	const areValid = areRecordsValid(records, metadata);

	if (!areValid) return {};

	const groupBy = label.column;

	const [columnA, columnB] = yAxis.columns;

	const recordsGroupedByTopic = records.reduce((acc, record) => {
		const key = record[groupBy];

		return {
			...acc,
			[key]: acc[key] !== undefined
				? acc[key].concat(record)
				: [record],
		};
	}, {});

	const componentData: TwoByTwoBipartitePoints[] = Object.values(recordsGroupedByTopic).map(recordGroup => {
		const recordA = recordGroup.filter((rg: any) => !!rg[columnA])[0];
		const recordB = recordGroup.filter((rg: any) => !!rg[columnB])[0];

		const { a: pointA, b: pointB } = buildDataPoints(recordA, recordB, metadata);

		const mediaMentionsA = buildMediaMentions(recordA);
		const mediaMentionsB = buildMediaMentions(recordB);

		return {
			a: {
				...pointA,
				mediaMentionData: {
					insights: recordA?.Insights ?? '',
					mediaMentions: mediaMentionsA,
					numMentions: mediaMentionsA.length,
					term: pointA.label,
				},
			},
			b: {
				...pointB,
				mediaMentionData: {
					insights: recordB?.Insights ?? '',
					mediaMentions: mediaMentionsB,
					numMentions: mediaMentionsB.length,
					term: pointB.label,
				},
			},
		};
	});

	const componentProps: TwoByTwoBipartiteProps = {
		axisProps: {
			...defaultAxisProps,
			leftAxisLabel: yAxis.label ?? '',
			bottomAxisLabel: xAxis.label ?? xAxis.column,
		},
		colorFnA: () => COLOR_LIGHT_BLUE,
		colorFnB: () => COLOR_DARK_BLUE,
		dataPaddingX: 5,
		dataPaddingY: 0.5,
		dotRadiusFn: () => 8,
		legendColorScale: scaleOrdinal({
			domain: ['2019', '2020'],
			range: [COLOR_LIGHT_BLUE, COLOR_DARK_BLUE],
		}),
		padding: {
			bottom: 40,
			left: 40,
			right: 80,
			top: 40,
		},
		horizontalCrossBarPosFn: (graphSize, xScale, yScale) => {
			return {
				x1: 0,
				x2: graphSize.width,
				y1: yScale(0),
				y2: yScale(0),
			};
		},
		verticalCrossBarPosFn: (graphSize, xScale) => {
			return {
				x1: xScale(144),
				x2: xScale(144),
				y1: 0,
				y2: graphSize.height,
			};
		},
	};

	return { componentData, componentProps };
};

export default TwoByTwoBipartite;
