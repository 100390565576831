import { useEffect, useState } from 'react';
import { ChannelType } from '../../services';

interface ChannelObserverProps {
	children: (value: { messageCount: number }) => JSX.Element;
	channel?: ChannelType;
}

export const ChannelObserver = (props: ChannelObserverProps): JSX.Element => {
	const { children, channel } = props;

	const [messageCount, setMessageCount] = useState(
		() =>
			channel?.state.messages.filter((message: any) => !message.deleted_at)
				.length ?? 0
	);

	useEffect(() => {
		if (channel) {
			const commentCount = channel?.state.messages
				.filter((message: any) => !message.deleted_at)
				.length ?? 0;

			setMessageCount(commentCount);
		}
	}, [channel]);

	useEffect(() => {
		const onNewMessage = () => {
			setMessageCount(count => count + 1);
		};

		channel?.on('message.new', onNewMessage);

		const onDeletedMessage = () => {
			setMessageCount(count => count - 1);
		};
		channel?.on('message.deleted', onDeletedMessage);

		return () => {
			channel?.off('message.new', onNewMessage);
			channel?.off('message.deleted', onDeletedMessage);
		};
	}, [channel]);

	return children({
		messageCount,
	});
};
