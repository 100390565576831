import { useCallback, useState, useRef } from 'react';

type ShowAnnotationArgs<AnnotationData> = Omit<UseAnnotationState<AnnotationData>, 'annotationIsOpen'>;
type UpdateAnnotationArgs<AnnotationData> = UseAnnotationState<AnnotationData>;

export interface UseAnnotationParams<AnnotationData> {
	annotationIsOpen: boolean;
	annotationData?: AnnotationData;
	updateAnnotation: (args: UpdateAnnotationArgs<AnnotationData>) => void;
	showAnnotation: (args: ShowAnnotationArgs<AnnotationData>) => void;
	hideAnnotation: () => void;
	currentEleRef: HTMLElement | undefined
}

export interface UseAnnotationState<AnnotationData> {
	referenceEle?: HTMLElement;
	annotationIsOpen: boolean;
	annotationData?: AnnotationData;
}

export default function useAnnotation<AnnotationData>(
	initialState: UseAnnotationState<AnnotationData> = {
		annotationIsOpen: false,
	}
): UseAnnotationParams<AnnotationData> {
	const currentEleRef = useRef<HTMLElement>();

	const [annotationState, setAnnotationState] =
		useState<UseAnnotationState<AnnotationData>>(initialState);

	const showAnnotation = useCallback(
		(showArgs: ShowAnnotationArgs<AnnotationData>) => {

			const { referenceEle, ...nextState } = showArgs;

			currentEleRef.current = referenceEle;

			setAnnotationState({
				...nextState,
				annotationIsOpen: true,
			});
		},
		[setAnnotationState]
	);

	const hideAnnotation = useCallback(
		() => {
			currentEleRef.current = undefined;
			setAnnotationState({
				annotationIsOpen: false,
				annotationData: undefined,
			});
		},
		[setAnnotationState]
	);

	return {
		currentEleRef: currentEleRef.current,
		annotationIsOpen: annotationState.annotationIsOpen,
		annotationData: annotationState.annotationData,
		updateAnnotation: setAnnotationState,
		showAnnotation,
		hideAnnotation,
	};
}
