import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(9),
		paddingBottom: theme.spacing(4),
	},

	sectionTitle: {
		display: 'inline-flex',
		alignItems: 'center',
		marginBottom: 30,
		color: theme.palette.text.primary,
		fontWeight: 700,
		'&::before': {
			content: '""',
			width: 10,
			height: 10,
			marginRight: 10,
			borderRadius: 2,
			backgroundColor: theme.palette.secondary.main,
			display: 'block',
		},
	},

	paper: {
		padding: theme.spacing(7, 6),
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(1),
		boxShadow: 'inset 0px 1px 3px rgba(217, 217, 217, 0.481316)',
		borderRadius: 10,
		cursor: 'pointer',
	},

	landscapeTitle: {
		flexGrow: 1,
		fontWeight: 500,
	},

	customFlag: {
		padding: theme.spacing(1, 2),
		color: theme.palette.secondary.main,
		backgroundColor: 'rgba(233, 10, 109, 0.08)',
		fontSize: 9,
		lineHeight: 1,
		fontWeight: 700,
		borderRadius: 4,
	},

	landscapeDescription: {
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		marginRight: theme.spacing(8),
	},

	landscapeCreated: {
		display: 'inline-flex',
		color: theme.palette.text.disabled,

		'& svg': {
			marginRight: theme.spacing(2),
		},
	},

	landscapeAvatars: {
		alignSelf: 'flex-end',

	},

	error: {
		color: theme.palette.secondary.main,
	},
}));


export const useAvatarGroupStyles = makeStyles(theme => ({
	root: {},
	avatar: {
		backgroundColor: '#FEECF4',
		color: theme.palette.secondary.main,
		fontSize: 11,
		fontWeight: 700,
		letterSpacing: 1.75,
	},
}));
