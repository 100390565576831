import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
	root: {
		overflow: 'hidden',
		position: 'relative',

		'& .vega-embed': {
			'&.has-actions': {
				position: 'unset',
			},
		},
	},
}));
