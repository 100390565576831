import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import InputBase from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import SearchIcon from '@material-ui/icons/Search';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useStore } from 'stores';
import User from 'assets/images/User2.png';
import { useAuthentication } from 'authentication';
import { Link, useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import SpeciateLogo from '../SpeciateLogo/SpeciateLogo';

const Header: React.FC = observer(() => {
	const authentication = useAuthentication();

	const { appStore } = useStore();

	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleLogout = useCallback(() => authentication.logOut(), []);

	const history = useHistory();
	const handleSettings = useCallback(() => history.push('/settings'), []);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const user = authentication.currentUser;

	return (
		<AppBar position='absolute' color='default' className={classes.appBar}>
			<Toolbar>
				<div className={classes.logoWrapper}>
					<IconButton className={classes.logo} color='inherit'>
						<Link to='/'>
							<SpeciateLogo
								className='Header__logo'
								fill='#08062f'
								height={28}
								width={28}
							/>
						</Link>
					</IconButton>
				</div>
				<div className={classes.spacer} />
				<div className={classes.separator} />
				{appStore.showDemoableItems && (
					<>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder='Search landscape'
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ 'aria-label': 'search' }}
							/>
						</div>
						<div className={classes.separator} />
						<IconButton color='inherit'>
							<Badge variant='dot' color='secondary'>
								<NotificationsIcon fontSize='small' />
							</Badge>
						</IconButton>
					</>
				)}

				<ButtonBase
					className={classes.profileMenu}
					aria-controls='profile-menu'
					aria-haspopup='true'
					onClick={handleClick}
				>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'
					>
						{appStore.showDemoableItems && (
							<Avatar className={classes.avatar} alt={user?.firstName} src={User} />
						)}
						<Typography variant='body2'>Welcome back, {user?.firstName}</Typography>
						<KeyboardArrowDownIcon />
					</Box>
				</ButtonBase>
				<Menu
					id='profile-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<MenuItem onClick={handleSettings}>Settings</MenuItem>
					<MenuItem onClick={handleLogout}>Logout</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
});

export default Header;
