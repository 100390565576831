import { fade, makeStyles } from '@material-ui/core/styles';
import { COLOR_LIGHT_GREY } from '../../theme/colors';
import { sidebarWidth, headerBorderWidth } from '../../theme/metrics';

export const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		boxShadow: 'none',
		backgroundColor: theme.palette.common.white,
		borderBottomStyle: 'solid',
		borderBottomWidth: headerBorderWidth,
		borderColor: COLOR_LIGHT_GREY,
		marginLeft: 0,
		width: '100%',
		zIndex: 1002,
	},
	logoWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	logo: {},
	spacer: {
		flexGrow: 1,
	},
	separator: {
		width: 2,
		backgroundColor: COLOR_LIGHT_GREY,
		alignSelf: 'stretch',
	},
	search: {
		position: 'relative',
		alignSelf: 'center',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '20ch',
			'&:focus': {
				width: '26ch',
			},
		},
	},
	profileMenu: {
		marginLeft: theme.spacing(6),
	},
	avatar: {
		width: 29,
		height: 29,
		marginRight: theme.spacing(2),
	},
}));
