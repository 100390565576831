import React from 'react';
import {
	ThemeProvider as MuiThemeProvider,
	Theme,
} from '@material-ui/core/styles';

export interface ThemeProviderProps {
	theme: Theme
	children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = props => (
	<MuiThemeProvider {...props} />
);
