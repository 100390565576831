import React, { useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { hashCode } from 'utils/id';
import { ANALYTICS_EVENTS } from 'constants/analytics-events';
import { Event } from 'stream-chat';
import { ChannelType, StreamChatService } from '../../services';
import analytics from '../../services/analytics';
import { Chat } from './Chat';
import { ChatContext, ChatProviderValue } from './ChatContext';
import { useStyles, useCloseButtonStyles, useDrawerClasses } from './styles';

interface ChatProviderProps {
	children: React.ReactNode;
	streamChat: StreamChatService;
}

const truncateChannelTitle = (t: string | undefined): string | undefined => {
	let truncated = t?.substr(0, 100);
	truncated += truncated == t ? '' : '...';
	return truncated;
};

export const ChatProvider: React.FC<ChatProviderProps> = ({
	children,
	streamChat,
}) => {
	const classes = useStyles();
	const closeButtonClasses = useCloseButtonStyles();
	const drawerClasses = useDrawerClasses();

	const [open, setOpen] = useState(false);
	const [channel, setChannel] = useState<ChannelType>();

	const currentEntityType = useRef(null);

	const openChat = (data: any) => {
		const { id, title, type } = data;

		const channel = streamChat.createChannel(
			id ?? String(hashCode(title)),
			title,
			{ type }
		);

		currentEntityType.current = type || 'unknown';

		analytics.track(ANALYTICS_EVENTS.OPEN_CHAT, {
			entity_type: currentEntityType.current,
			entity_id: id,
		});

		if (channel?.data?.name !== undefined) {
			channel.data.name = truncateChannelTitle(channel?.data?.name);
		}

		setChannel(channel);

		setOpen(true);
	};

	useEffect(() => {
		const onNewMessage = (event: Event) => {
			analytics.track(ANALYTICS_EVENTS.MESSAGE_SENT, {
				entity_type: currentEntityType.current,
				entity_id: event.channel_id,
			});
		};

		channel?.on('message.new', onNewMessage);

		return () => {
			channel?.off('message.new', onNewMessage);
		};
	}, [channel]);

	const closeChat = () => {
		setOpen(false);
	};

	const value = useMemo(() => ({ openChat, closeChat, streamChat }), []);

	const renderChildren = useMemo(() => {
		return children;
	}, []);

	return (
		<ChatContext.Provider value={value}>
			{renderChildren}
			<Drawer
				anchor='right'
				open={open}
				onClose={closeChat}
				PaperProps={{
					elevation: 4,
				}}
				classes={drawerClasses}
			>
				<div className={classes.closeButtonContainer}>
					<div className={classes.spacer} />
					<div className={classes.buttonShadow} />
					<IconButton
						edge='start'
						color='secondary'
						aria-label='open drawer'
						onClick={closeChat}
						classes={closeButtonClasses}
					>
						<CloseIcon />
					</IconButton>
				</div>

				<div className={classes.container}>
					<Chat channel={channel} streamChat={streamChat} />
				</div>
			</Drawer>
		</ChatContext.Provider>
	);
};

export const useChat: () => ChatProviderValue = () => {
	const chat = useContext(ChatContext);

	if (chat === null) {
		throw new Error('Chat should be provided in ChatProvider');
	}

	return chat;
};

export function useChatChannels(
	channelIds: string[],
	options: { prefix?: string } = {}
): {
		channels: ChannelType[];
		isFetching: boolean;
		getChannel: (id: string) => ChannelType | undefined;
	} {
	const chat = useChat();

	const { prefix = '' } = options;

	const [channels, setChannels] = useState<ChannelType[]>([]);
	const [isFetching, setIsFetching] = useState(true);

	const queryChannel = async () => {
		if (channelIds.length === 0) {
			setIsFetching(false);
			return;
		}

		try {
			let ids = channelIds;

			if (prefix !== '') ids = channelIds.map(id => `${prefix}_${id}`);

			const channels = await chat.streamChat.queryChannels(ids);

			setChannels(channels);
		} catch (e) {
			console.error(e);
		} finally {
			setIsFetching(false);
		}
	};

	useEffect(() => {
		queryChannel();
	}, [channelIds]);

	const getChannel = useCallback(
		(id: string) => channels.find(channel => channel?.data?.id === `${prefix}_${id}`),
		[channels, prefix]
	);

	return { channels, isFetching, getChannel };
}

export function useChatChannel(channelId: string): ChannelType | undefined {
	const chat = useChat();

	const [channel, setChannel] = useState<ChannelType>();

	const queryChannel = async () => {
		const channel = await chat.streamChat.queryChannel(channelId);
		setChannel(channel);
	};

	useEffect(() => {
		queryChannel();
	}, []);

	return channel;
}
