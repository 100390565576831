import { createMuiTheme, Theme } from '@material-ui/core/styles';

export const theme: Theme = createMuiTheme({
	spacing: 4,
	palette: {
		secondary: {
			main: '#E90A6D',
		},
		text: {
			primary: '#0A142B',
			secondary: '#464646',
		},
	},
	overrides: {
		MuiButton: {
			root: {
				fontStyle: 'normal',
			},
		},
	},
	typography: {
		fontFamily: ['"Ubuntu"', '"Helvetica Neue"', 'Arial'].join(','),
		h5: {
			fontFamily: '"Lora"',
		},
		h4: {
			fontFamily: '"Lora"',
		},
		h3: {
			fontFamily: '"Lora"',
		},
		h2: {
			fontFamily: '"Lora"',
		},
		h1: {
			fontFamily: '"Lora"',
		},
		body1: {
			fontWeight: 500,
		},
		button: {
			fontSize: '12px',
			letterSpacing: 1.7,
			fontWeight: 700,
		},
	},
});
