import parse from 'csv-parse/lib/sync';
import { DataRecord, PageCardComponentType } from 'typings/app';

export function stripHtml(html: string, maxLength = -1): string {
	const tmp = document.createElement('DIV');
	tmp.innerHTML = html;
	const text = tmp.textContent || tmp.innerText || '';

	if (maxLength > 0) {
		if (text.length <= maxLength) return text;
		return text.substr(0, text.lastIndexOf(' ', maxLength)) + '...';
	}
	return text;
}

interface parseOptions {
	columns?: boolean;
	delimiter?: string;
	quote?: string;
	type?: PageCardComponentType;
}

export const defaultParseOptions: parseOptions = {
	columns: true,
	delimiter: '\t',
	quote: '',
};

export function parseCsv(
	data: string,
	options: parseOptions = defaultParseOptions
): DataRecord[] {
	if (options.type == 'HtmlBlob') {
		return [{ html: data }];
	}

	return parse(data, options);
}
