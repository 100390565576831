import React from 'react';
import { StreamChatService } from '../../services';

export interface ChatProviderValue {
	openChat: (data: any) => void,
	closeChat: () => void
	streamChat: StreamChatService,
}

const ChatContext = React.createContext<ChatProviderValue>({} as ChatProviderValue);

export { ChatContext };
